import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { Input, Avatar, Row, Col, Card } from "antd";
import Button from "@mui/material/Button";
import ModalSuccess from "../../../../components/modal/success";
import SuccessCheckIcon from "../../../../assets/svgs/success-check";
import InvestorsList from "./pages/investors-list";
import InvestorsScore from "./pages/investors-score";
import SynergiesAndGoals from "./pages/synergies-and-goals";
import InvestorProfile from "./pages/investors-profile";
import {
  countryState,
  countryStateAbbrev,
} from "../../constants/country-state";

const AIRecommendation = () => {
  const { http } = global.services;
  const DATA_LIMIT = 3;

  const selectedCompany = useSelector(
    (state) => state.CompanyReducer.selected_company
  );

  const [state, setState] = useState({
    selectedRowKeys: [0],
    profile: {},
  });
  const [search, setSearch] = useState("");
  const [selectedFilters, setselectedFilters] = useState(null);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [investors, setInvestors] = useState([]);
  const [visible, setVisible] = useState(false);

  const history = useHistory();

  const getInvestorsHelper = async (companyid, isAll) => {
    const params = {
      ...selectedFilters,
      offset: isAll ? 0 : (page - 1) * DATA_LIMIT,
      search,
    };
    if (!isAll) {
      params["limit"] = DATA_LIMIT;
    }
    Object.keys(params).forEach(
      (key) => params[key] === undefined && delete params[key]
    );
    const queryparams = new URLSearchParams(params).toString();
    const { data } = await http.get(
      `company-buyers/${companyid}?${queryparams}`
    );
    const buyers = data?.data;
    return {
      buyers,
      total: data?.total,
    };
  };
  useEffect(() => {
    init();
  }, [page, selectedCompany, search, selectedFilters]);

  const init = async () => {
    const { id } = selectedCompany;
    if (id) {
      const { buyers, total } = await getInvestorsHelper(id);
      setTotal(total);
      let newState = { ...state };
      newState.selectedRowKeys = [0];
      if (buyers) {
        newState.profile = buyers?.length > 0 ? buyers[0].programx_output : {};
        setInvestors(buyers);
        setState(newState);
      }
    }
  };
  const setFiltersHandler = (value) => {
    setselectedFilters(value);
  };
  const handlePageChange = (page) => {
    setPage(page);
  };
  const setSearchHandler = (val) => {
    setSearch(val);
  };

  const changeSelected = (index) => {
    setState({
      ...state,
      selectedRowKeys: index,
      profile: investors[index[0]].programx_output,
    });
  };

  const convertToStateName = (country, stateAbbreviation) => {
    let index = countryStateAbbrev[country].findIndex(
      (sa) => sa === stateAbbreviation
    );
    return countryState[country][index];
  };

  const addToLeadsManager = async () => {
    let profile_state =
      state.profile.country && state.profile.state
        ? convertToStateName(state.profile.country, state.profile.state)
        : "-";
    let profile_country =
      state.profile.country && state.profile.state
        ? convertToStateName(state.profile.country, state.profile.state)
        : "-";

    let params = {
      user_company_id: selectedCompany.id,
      company_name: selectedCompany.company_name,
      company_logo: state.profile.company_logo,
      lead_name: state.profile.company_name,
      url: state.profile.url,
      email: "",
      contact_number: "",
      is_recommended: 1,
      user_notes: "",
      status: "new lead",
      state: profile_state,
      country: profile_country,
      location: state.profile.hq_address,
      recommended_lead_notes: {
        financial_synergies: state.profile.financial_synergies,
        misc_synergies: state.profile.misc_synergies,
        operating_synergies: state.profile.operating_synergies,
      },
    };

    try {
      let { data } = await http.post(`user-lead`, params);
      setVisible(true);
    } catch (err) {
      console.log(`Something went wrong in the server! Please try again.`);
    }
  };

  const handleClose = () => {
    setVisible(false);
  };

  const redirect = () => {
    history.push("/leads-manager");
  };
  return (
    <React.Fragment>
      <strong>
        <h2 className="dashboard-header">Buyer's Universe</h2>
      </strong>
      {visible && (
        <ModalSuccess
          className="program-x-modal"
          show={visible}
          onClose={handleClose}>
          <div className="program-x-modal-success-content">
            <SuccessCheckIcon />
            <h1 className="title">Successfully added</h1>
            {/* <p className='description'>{company}</p> */}
            <Button
              className="btn btn-primary"
              type="primary"
              onClick={handleClose}>
              Ok
            </Button>
          </div>
        </ModalSuccess>
      )}
      <Row gutter={[32, 16]}>
        <Col span={14}>
          <InvestorsList
            selectedCompany={selectedCompany}
            data={investors || []}
            selected={state.profile}
            state={state}
            onChange={changeSelected}
            onAdd={addToLeadsManager}
            datalimit={DATA_LIMIT}
            total={total}
            pageChanger={handlePageChange}
            search={search}
            setSearch={setSearchHandler}
            selectedFilter={selectedFilters}
            setFilters={setFiltersHandler}
            getInvestors={getInvestorsHelper}
          />
          <div className="spacer"></div>
          <SynergiesAndGoals data={state.profile} />
        </Col>
        <Col span={10}>
          <InvestorProfile data={state.profile} />
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default AIRecommendation;
