export const multipleTypes = [
    'multi_select',
    'input_tag'
]

export const textInputTypes = [
    'input',
    'number'
]

export const optionTypes = [
    'radio',
    'checkbox',
    'select',
    'multi_select',
    'input_tag'
]