// Carousel.js
import { useState, useEffect, useRef } from "react";
import NoDataIcon from "../../../../../assets/svgs/no-data.svg";
import { fetchPrivateImage } from "../../../../../helpers/fetchImage";
import { BounceLoader } from "react-spinners";
import SourceModal from "../../../../../components/source-modal";

const InvestorLogoBox = ({ data }) => {
  const [isLoading, setLoading] = useState(true);
  const [logo, setLogo] = useState(null);
  useEffect(() => {
    setLoading(true);
    fetchPrivateImage(data.target_logo)
      .then((res) => {
        setLogo(res);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, [data]);
  return (
    <div className="thumb">
      {isLoading ? (
        <BounceLoader size={10} color="rgb(234, 187, 254)" />
      ) : (
        <img src={logo} alt={data.name} />
      )}
    </div>
  );
};

export default function List({ activities = [], activitiesCompany = [] }) {
  const [selectedData, setselectedData] = useState(null);
  const [sourcePreview, setsourcePreview] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [logo, setLogo] = useState(null);

  const handleSourcePreview = (data) => {
    let sources = [];
    if (data.source) {
      sources = data.source.split(",");
    }
    setselectedData(sources);
    setsourcePreview(true);
  };

  useEffect(() => {
    if (activities.length === 0) return;
    setLoading(true);
    fetchPrivateImage(activities[0]?.logo)
      .then((res) => {
        setLogo(res);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, [activities]);
  return (
    <div className="activities-content" style={{ paddingBottom: 0 }}>
      {activities.length !== 0 ? (
        <>
          <div className="companyTitleWrap">
            {isLoading ? (
              <BounceLoader size={13} color="rgb(234, 187, 254)" />
            ) : (
              <img
                src={logo}
                className="companyTitleImg"
                alt={activitiesCompany.company_name}
              />
            )}
          </div>
          <div className="activities-list">
            <div className="activity-row header">
              <div className="activity-col name">
                <span>Name</span>
              </div>
              <div className="activity-col type">
                <span>Type</span>
              </div>
              <div className="activity-col amount">
                <span>Amount</span>
              </div>
              <div className="activity-col date">
                <span>Date</span>
              </div>
              <div className="activity-col status">
                <span>Status</span>
              </div>
              <div className="activity-col source">
                <span>Source</span>
              </div>
            </div>
            {activities.map((activity, index) => (
              <div className="activity-row" key={index}>
                <div className="activity-col name">
                  <InvestorLogoBox data={activity} />
                  <span>{activity.name}</span>
                </div>
                <div className="activity-col type">
                  <span>{activity.transaction_type}</span>
                </div>
                <div className="activity-col amount">
                  <span>{activity.amount}</span>
                </div>
                <div className="activity-col date">
                  <span>{activity.date_of_transaction}</span>
                </div>
                <div className="activity-col status">
                  <span
                    className={`badge ${
                      activity.status?.toLowerCase() === "completed"
                        ? "completed"
                        : ""
                    }`}
                  >
                    {activity.status}
                  </span>
                </div>
                <div className="activity-col source">
                  <button onClick={() => handleSourcePreview(activity)}>
                    View
                  </button>
                </div>
              </div>
            ))}
          </div>
        </>
      ) : (
        <>
          <div className="no-activities-data">
            <img src={NoDataIcon} alt="no-data" />
            No Activity Found
          </div>
        </>
      )}
      <SourceModal
        show={sourcePreview}
        sources={selectedData}
        onClose={() => setsourcePreview(false)}
      />
    </div>
  );
}
