import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import Cookie from "js-cookie";
import { EyeOutlined, EyeInvisibleOutlined } from "@ant-design/icons";
import { Card, Input, Button, Alert } from "antd";
import MainLayout from "./components/layout";
import {
  role,
  session_time,
  token_leads,
  token_user,
} from "../../../../helpers/auth";
import OtpInput from "react-otp-input";
import { SESSION_TIME } from "../../../../constant";

const LoginPage = () => {
  const resendTime = 30; // SECONDS
  const { http } = global.services;
  const [verificationView, setVerificationView] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [secondsRemaining, setSecondsRemaining] = useState(0);
  const [resendPreview, setResendPreview] = useState(false);
  const [user, setUser] = useState(null);
  const [otp, setOtp] = useState("");
  const [form, setForm] = useState({
    username: "",
    password: "",
    showPassword: false,
  });

  const [errors, setErrors] = useState({});
  const loginBtnRef = useRef();

  if (session_time) {
    const expirationTime = parseInt(session_time);
    const currentTime = Date.now();
    if (currentTime < expirationTime) {
      if (role === "leads" && token_leads) {
        window.location.replace("/leads");
      } else if (role === "user" && token_user) {
        window.location.replace("/home");
      }
    } else {
      Cookie.remove("role");
      Cookie.remove("token_leads");
      Cookie.remove("token_user");
      Cookie.remove("sessionTime");
    }
  } else {
    if (role === "leads" && token_leads) {
      window.location.replace("/leads");
    } else if (role === "user" && token_user) {
      window.location.replace("/home");
    }
  }

  const onLogin = async () => {
    let error = "";
    try {
      let formData = form;
      let emailValidation =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      if (emailValidation.test(form.username)) {
        formData = {
          email: form.username,
          password: form.password,
        };
      }
      setLoading(true);
      let { data } = await http.post("login", formData);
      setUser(data?.user_id);
      setLoading(false);
      setVerificationView(true);
      setSecondsRemaining(resendTime);
      setResendPreview(false);
    } catch (err) {
      setLoading(false);
      const { data, status } = err.response;
      if (status === 400) {
        error = { message: data.non_field_errors };
      }
    }

    setErrors(error || {});
  };
  const onVerification = async () => {
    let error = "";
    try {
      setLoading(true);
      let { data } = await http.post(`otp-verification/${user}`, { otp: otp });
      setLoading(false);
      const loginTime = Date.now();
      const sessionExpiration = loginTime + SESSION_TIME * 60 * 60 * 1000;
      // const sessionExpiration = loginTime + (20 * 1000);
      Cookie.set("role", "leads");
      Cookie.set("token_leads", data.token);
      Cookie.set("is_logged_once", JSON.stringify(data.is_logged_once));
      Cookie.set("sessionTime", sessionExpiration);
      if (data.token) localStorage.setItem("modal", 1);
      if (data.is_logged_once === 0) {
        window.location.href = "/set-password";
      } else {
        window.location.href = "/leads";
      }
    } catch (err) {
      setLoading(false);
      const { data, status } = err.response;
      if (status === 400) {
        error = { message: data.non_field_errors };
      }
    }

    setErrors(error || {});
  };
  const onResend = async () => {
    let error = "";
    setResendPreview(false);
    setSecondsRemaining(resendTime);
    try {
      await http.post(`resend-otp/${user}`);
    } catch (err) {
      const { data, status } = err.response;
      if (status === 400) {
        error = { message: data.non_field_errors };
      }
    }

    setErrors(error || {});
  };
  const submitHandler = () => {
    if (verificationView) {
      onVerification();
      return;
    }
    onLogin();
  };

  useEffect(() => {
    const listener = (event) => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        loginBtnRef.current.click();
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, []);

  useEffect(() => {
    let intervalId;
    if (secondsRemaining > 0) {
      intervalId = setInterval(() => {
        setSecondsRemaining((prevSeconds) => Math.max(0, prevSeconds - 1));
      }, 1000);
    } else {
      setResendPreview(true);
    }
    return () => clearInterval(intervalId);
  }, [secondsRemaining]);

  const change = (props) => (e) => {
    let data = { ...form };
    data[props] = e.target.value;
    setForm(data);
  };

  const formattedTime = `${Math.floor(secondsRemaining / 60)
    .toString()
    .padStart(2, "0")}:${secondsRemaining % 60 < 10 ? "0" : ""}${
    secondsRemaining % 60
  }`;

  return (
    <React.Fragment>
      <MainLayout>
        <div className="flex justify-center items-center">
          <Card bordered={false}>
            <div className="flex-column items-center" style={{ padding: 40 }}>
              <strong>
                <h1 style={{ fontSize: 25 }}>
                  {verificationView ? "Verification Required" : "Login"}
                </h1>
              </strong>
              {errors.message && (
                <>
                  <Alert
                    message={errors.message[0]}
                    style={{ width: "100%", textAlign: "center" }}
                    type="error"
                  />
                  <br />
                </>
              )}
              <div className="flex flex-column flex-grow">
                <p style={{ opacity: 0.7 }}>
                  {verificationView
                    ? `Enter the code sent to your email address.`
                    : "Enter your credentials to acces your account."}
                </p>
                {verificationView ? (
                  <>
                    <OtpInput
                      containerStyle="otp-container"
                      inputStyle="otp-input"
                      value={otp}
                      onChange={setOtp}
                      numInputs={6}
                      renderSeparator={<span></span>}
                      renderInput={(props) => <input {...props} />}
                      inputType="tel"
                      shouldAutoFocus={verificationView}
                    />
                    <p className="resend-otp">
                      Didn't recieve a code?{" "}
                      <span
                        onClick={() => resendPreview && onResend()}
                        className={`resend-btn ${
                          !resendPreview ? "disabled" : ""
                        }`}
                      >
                        Resend
                      </span>
                      <span className="countdown">{formattedTime}</span>
                    </p>
                  </>
                ) : (
                  <>
                    <div style={{ marginBottom: 16 }}>
                      <span style={{ display: "block" }}>
                        Email Address / Username
                      </span>
                      <Input
                        value={form.username}
                        className="inputs"
                        placeholder=""
                        onChange={change("username")}
                      />
                    </div>
                    <div style={{ marginBottom: 32 }}>
                      <div className="flex justify-between">
                        <span>Password</span>
                        <Link
                          to="/forgot-password"
                          style={{ color: "#605BFF" }}
                        >
                          Forgot Password?
                        </Link>
                      </div>
                      <Input.Group compact>
                        <Input
                          value={form.password}
                          type={form.showPassword ? "text" : "password"}
                          className="inputs"
                          onChange={change("password")}
                          suffix={
                            form.showPassword ? (
                              <EyeInvisibleOutlined
                                onClick={() =>
                                  setForm({ ...form, showPassword: false })
                                }
                              />
                            ) : (
                              <EyeOutlined
                                onClick={() =>
                                  setForm({ ...form, showPassword: true })
                                }
                              />
                            )
                          }
                        />
                      </Input.Group>
                    </div>
                  </>
                )}
                <Button
                  type="primary"
                  block
                  loading={isLoading}
                  ref={loginBtnRef}
                  style={{
                    color: "#fff",
                    borderColor: "#605BFF",
                    backgroundColor: "#605BFF",
                    borderRadius: 8,
                  }}
                  onClick={submitHandler}
                >
                  {verificationView ? "Verify code" : "Login"}
                </Button>
              </div>
            </div>
          </Card>
        </div>
      </MainLayout>
    </React.Fragment>
  );
};

export default LoginPage;
