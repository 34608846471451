import * as React from "react"

const SvgComponent = (props) => (
  <svg
    width={1231}
    height={807}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g opacity={0.2}>
      <path
        d="M219.496-9.866s204.878 7.237 302.307 75.317c112.886 78.881 60.116 274.515 193.054 317.375 114.388 36.88 222.814-108.677 340.423-79.142 123.69 31.061 100.11 199.888 220.92 237.557 74.89 23.35 212.57 1.464 212.57 1.464"
        stroke="#D0BBFE"
      />
      <path
        d="M83.164-41.745s205.342 7.514 303.08 75.779c113.243 79.094 60.621 274.816 193.913 317.888 114.691 37.061 223.161-108.47 341.074-78.754 124.009 31.25 100.599 200.183 221.729 238.042 75.09 23.469 213.05 1.748 213.05 1.748"
        stroke="#999FFB"
      />
      <path
        d="M307.593 15.963s158.52 28.112 241.447 83.38c96.084 64.037 78.304 184.818 185.616 227.798 92.338 36.983 158.795-44.975 252.758-12.385C1086.23 349.03 1087.79 455.772 1185.16 494c60.36 23.697 163.77 25.249 163.77 25.249"
        stroke="#CBB8FE"
      />
      <path
        d="M298.374 12.45S456.641 40.976 539.59 96.21c96.109 63.995 78.827 183.961 186.058 227.072 92.269 37.096 158.265-44.107 252.138-11.37 98.724 34.428 100.684 140.513 197.974 178.865 60.31 23.774 163.49 25.701 163.49 25.701"
        stroke="#C6B6FD"
      />
      <path
        d="M288.929 9.32s158.52 28.112 241.447 83.38C626.46 156.739 608.68 277.52 715.992 320.5c92.338 36.982 158.795-44.976 252.758-12.385 98.82 34.274 100.37 141.015 197.74 179.243 60.36 23.697 163.77 25.25 163.77 25.25"
        stroke="#C1B3FD"
      />
      <path
        d="M279.261 6.549s158.52 28.112 241.447 83.38c96.084 64.037 78.304 184.818 185.616 227.798 92.338 36.983 158.795-44.975 252.758-12.385 98.818 34.274 100.378 141.016 197.748 179.244 60.35 23.697 163.76 25.249 163.76 25.249"
        stroke="#BCB1FD"
      />
      <path
        d="M268.402 3.064S427.077 31.27 510.107 86.6c96.203 64.108 78.472 184.919 185.902 227.969 92.439 37.043 158.911-44.906 252.973-12.256C1047.9 336.649 1049.52 443.426 1147 481.718c60.42 23.736 163.92 25.344 163.92 25.344"
        stroke="#B7AEFD"
      />
      <path
        d="M258.738.293s158.52 28.112 241.447 83.38c96.084 64.037 78.304 184.818 185.616 227.798 92.338 36.983 158.795-44.975 252.757-12.385C1037.37 333.36 1038.93 440.102 1136.3 478.33c60.36 23.697 163.77 25.249 163.77 25.249"
        stroke="#B2ACFC"
      />
      <path
        d="M249.066-2.479s158.52 28.112 241.447 83.38C586.597 144.94 568.817 265.72 676.129 308.7c92.338 36.982 158.795-44.976 252.757-12.385 98.814 34.274 100.374 141.016 197.744 179.243 60.36 23.698 163.77 25.25 163.77 25.25"
        stroke="#ADA9FC"
      />
      <path
        d="M240.078-6.355s158.519 28.112 241.447 83.38c96.084 64.038 78.304 184.819 185.616 227.799 92.337 36.982 158.795-44.976 252.757-12.385 98.812 34.274 100.372 141.016 197.742 179.243 60.36 23.698 163.77 25.25 163.77 25.25"
        stroke="#A8A7FC"
      />
      <path
        d="M230.402-9.126s158.52 28.112 241.447 83.38c96.084 64.037 78.304 184.818 185.616 227.798 92.338 36.983 158.795-44.975 252.757-12.385 98.818 34.274 100.378 141.016 197.748 179.244 60.36 23.697 163.76 25.25 163.76 25.25"
        stroke="#A3A4FB"
      />
      <path
        d="M219.55-12.61s158.675 28.204 241.705 83.534c96.203 64.108 78.473 184.919 185.902 227.969 92.439 37.043 158.911-44.906 252.974-12.255 98.921 34.337 100.539 141.114 198.019 179.405 60.42 23.737 163.92 25.344 163.92 25.344"
        stroke="#9EA2FB"
      />
    </g>
  </svg>
)

export default SvgComponent
