import React, { useRef, useState } from "react";
import { useHistory } from "react-router-dom";

import { Input, Typography } from "antd";
import { SearchOutlined, UserOutlined } from "@ant-design/icons";

import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";

import LeadsList from "./list";
import { useSelector } from "react-redux";
import { pdf } from "@react-pdf/renderer";
import { saveAs } from "file-saver";
import { LeadsPDF } from "../../../../../helpers/pdfGenerator";
import { leadListManipulator } from "../../../../../helpers/manuplator";
import { CSVLink } from "react-csv";

const { Title } = Typography;

const LeadsContent = (props) => {
  const CSV_HEADERS = [
    { label: "Name", key: "name" },
    { label: "Website", key: "website" },
    { label: "Status", key: "status" },
    { label: "Client’s Goals", key: "goals" },
    { label: "Target's Motivations", key: "motivations" },
    { label: "Synergies", key: "synergies" },
  ];

  const [search, setSearch] = useState("");
  const [isPdfLoading, setPdfLoading] = useState(false);

  const isBtnDisabled = props.companies?.length < 1;
  const downloadCsvBtn = useRef(null);
  const history = useHistory();
  const selectedCompany = useSelector(
    (state) => state.CompanyReducer.selected_company
  );

  const add = () => {
    history.push("/leads-manager/create");
  };

  const downloadPDF = async (event) => {
    event.preventDefault();
    setPdfLoading(true);
    const blob = await pdf(
      <LeadsPDF
        data={props.companies}
        company={selectedCompany?.company_name}
      />
    ).toBlob();
    setPdfLoading(false);
    saveAs(blob, selectedCompany?.company_name + " Leads.pdf");
  };

  const downloadCsv = () => {
    downloadCsvBtn?.current?.link.click();
  };

  return (
    <React.Fragment>
      <div style={{ display: "none" }}>
        <CSVLink
          data={leadListManipulator(props?.companies)}
          ref={downloadCsvBtn}
          filename={selectedCompany?.company_name + " Leads"}
          headers={CSV_HEADERS}
        />
      </div>
      <div className="flex flex-row justify-between items-center">
        <Title level={5}>Leads List</Title>
        <div
          className="flex flex-row justify-between items-center"
          style={{ gap: 10 }}
        >
          <div className="search-input">
            <Input
              value={search}
              size={90}
              placeholder="Search by name"
              prefix={<SearchOutlined />}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
          <Button
            className="download-btn large"
            disabled={isBtnDisabled || isPdfLoading}
            onClick={downloadPDF}
          >
            {isPdfLoading ? "Loading" : "Download PDF"}
          </Button>
          <Button
            className="download-btn large"
            onClick={downloadCsv}
            disabled={isBtnDisabled}
          >
            Download CSV
          </Button>
        </div>
      </div>
      <br />
      <LeadsList search={search} {...props} />
      <div className="spacer"></div>
      <div className="flex">
        <div className="flex-1"></div>
        <Button
          variant="contained"
          sx={{ backgroundColor: "#605BFF" }}
          startIcon={<AddIcon />}
          onClick={add}
        >
          Add New
        </Button>
      </div>
    </React.Fragment>
  );
};

export default LeadsContent;
