import React from 'react'

const NotFoundPage = () => {

    return (
        <h1 className="text-3xl font-bold underline">
            Page not found!
        </h1>
    )
}

export default NotFoundPage