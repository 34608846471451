import * as React from "react"

const SvgComponent = (props) => (
  <svg
    width={24}
    height={23}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g
      clipPath="url(#a)"
      stroke="#605BFF"
      strokeWidth={0.8}
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M16.613 20.11v-1.915a3.83 3.83 0 0 0-3.83-3.83H5.12a3.83 3.83 0 0 0-3.83 3.83v1.915M8.952 10.534a3.83 3.83 0 1 0 0-7.661 3.83 3.83 0 0 0 0 7.66ZM22.359 20.11v-1.915a3.83 3.83 0 0 0-2.873-3.706M15.655 2.997a3.831 3.831 0 0 1 0 7.422" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M.333 0h22.983v22.983H.333z" />
      </clipPath>
    </defs>
  </svg>
)

export default SvgComponent
