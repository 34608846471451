import * as React from "react"

const SvgComponent = (props) => (
  <svg
    width={23}
    height={44}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      opacity={0.7}
      d="M11.046 32.188v-14.83h.818v14.83h-.819ZM4.04 25.181v-.818h14.83v.818H4.04Z"
      fill="#5932EA"
    />
  </svg>
)

export default SvgComponent
