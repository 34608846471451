import * as React from "react"

const SvgComponent = (props) => (
  <svg
    width={32}
    height={32}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M19.333 2.667H8a2.667 2.667 0 0 0-2.667 2.666v21.334A2.667 2.667 0 0 0 8 29.333h16a2.666 2.666 0 0 0 2.667-2.666V10l-7.334-7.333Z"
      stroke="#5932EA"
      strokeWidth={0.8}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.667 2.667v8h8M21.333 17.333H10.667M21.333 22.667H10.667M13.333 12h-2.666"
      stroke="#5932EA"
      strokeWidth={0.8}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default SvgComponent
