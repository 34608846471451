import React from "react";
import AttribuesThumnail from "../../../../../assets/images/attributes";

const ExtrinsicContent = ({ attributes }) => {
  let extrinsic_input =
    attributes.extrinsic_input != "none" && Object.keys(attributes).length > 0
      ? attributes.extrinsic_input
      : 0;
  if (extrinsic_input >= 1000000) {
    if (extrinsic_input % 1000000 === 0) {
      extrinsic_input = extrinsic_input / 1000000 + "mm";
    } else {
      extrinsic_input = parseFloat(extrinsic_input / 1000000).toFixed(2) + "mm";
    }
  } else if (extrinsic_input >= 100000 || extrinsic_input <= -100000) {
    if (extrinsic_input % 1000 === 0) {
      extrinsic_input = extrinsic_input / 1000 + "k";
    } else {
      extrinsic_input = parseFloat(extrinsic_input / 1000).toFixed(2) + "k";
    }
  } else if (extrinsic_input >= 10000 || extrinsic_input <= 10000) {
    if (extrinsic_input % 1000 === 0) {
      extrinsic_input = extrinsic_input / 1000 + "k";
    } else {
      extrinsic_input = parseFloat(extrinsic_input / 1000).toFixed(2) + "k";
    }
  } else if (extrinsic_input >= 1000 || extrinsic_input <= 1000) {
    if (extrinsic_input % 10000 === 0) {
      extrinsic_input = extrinsic_input / 10000 + "k";
    } else {
      extrinsic_input = parseFloat(extrinsic_input / 10000).toFixed(2) + "k";
    }
  }

  const formatValue = (val) => {
    let value = val;

    if (typeof val === "string") {
      value = value ? value.replaceAll(/[mmk]/g, "") : value;
    }

    return value;
  };

  const formatSuffix = (val) => {
    let value = "";

    if (typeof val === "string") {
      value = val.includes("mm") ? "mm" : val.includes("k") ? "k" : "";
    }

    return value;
  };

  return (
    <div className="stats">
      <img className="thumb" src={AttribuesThumnail.Extrinsic} alt="" />
      <h4>Extrinsic <i>({formatSuffix(extrinsic_input)})</i></h4>
      <h2>
        {extrinsic_input.toString().includes("-") && "-"}$
        {formatValue(extrinsic_input.toString().replaceAll("-", ""))}
        {/* {formatSuffix(extrinsic_input)} */}
      </h2>
    </div>
  );
};

export default ExtrinsicContent;
