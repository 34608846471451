import * as React from "react"

const SvgComponent = ({ width = 20, height = 20, stroke = '#fff', ...props }) => (
  <svg
    width={width}
    height={height}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10 .833v18.334M14.167 4.167h-6.25a2.917 2.917 0 1 0 0 5.833h4.166a2.917 2.917 0 1 1 0 5.833H5"
      stroke={stroke}
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default SvgComponent
