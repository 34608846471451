import React, { useEffect, useState, useRef } from "react";
import { Table, Button, Input, Card, Typography, Space, Avatar } from "antd";
import { pdf } from "@react-pdf/renderer";
import { saveAs } from "file-saver";
import { SearchOutlined } from "@ant-design/icons";
import ProgressBar from "../components/progressbar";
import FilterDrawer from "../components/drawer";
import { CSVLink } from "react-csv";
import { buyerListMauplator } from "../../../../../helpers/manuplator";
import { BuyerPDF } from "../../../../../helpers/pdfGenerator";
import { fetchPrivateImage } from "../../../../../helpers/fetchImage";
import { BounceLoader } from "react-spinners";
import { IMAGE_HOST } from "../../../../../constant";

const { Title } = Typography;
const CSV_HEADERS = [
  { label: "Name", key: "name" },
  { label: "Website", key: "website" },
  { label: "Client’s Goals", key: "goals" },
  { label: "Target's Motivations", key: "motivations" },
  { label: "Synergies", key: "synergies" },
];

const InvestorDetailCol = ({ data }) => {
  const [isLoading, setLoading] = useState(true);
  const [logo, setLogo] = useState(null);
  useEffect(() => {
    setLoading(true);
    fetchPrivateImage(data.avatar)
      .then((res) => {
        setLogo(res);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, [data]);

  return (
    <div className="space-align-block">
      <Space align="center">
        {isLoading ? (
          <Avatar
            shape="square"
            size="large"
            style={{ backgroundColor: "transparent" }}
            icon={<BounceLoader size={9} color="rgb(234, 187, 254)" />}
          />
        ) : (
          <img className="buyer-list-thumb" src={logo} alt={data.name} />
        )}
        {/* {isLoading ? (
          <span className="buyer-list-thumb-box" />
        ) : (
          <img className="buyer-list-thumb" src={logo} alt={data.name} />
        )} */}
        <span className="mock-block buyers-list-name">{data.name}</span>
      </Space>
    </div>
  );
};

const InvestorsList = ({
  data = [],
  state = {},
  onChange,
  onAdd,
  selectedCompany = {},
  datalimit,
  total,
  pageChanger,
  search,
  setSearch,
  selectedFilter,
  setFilters,
  getInvestors,
}) => {
  const [isFilterOpen, setFilterOpen] = useState(false);
  const [isRemoved, setRemoved] = useState(false);
  const [investors, setInvestors] = useState([]);
  const [isPdfLoading, setPdfLoading] = useState(false);
  const [isCsvLoading, setCsvLoading] = useState(false);
  const [selectedInvestors, setselectedInvestors] = useState([]);
  const downloadCsvBtn = useRef(null);
  const pagination = {
    pageSize: datalimit,
    showQuickJumper: true,
    position: ["bottomLeft"],
    total: total,
    showSizeChanger: false,
    // locale: { jump_to: "Your text", page: "Your page" },
  };

  const filterToggle = () => {
    setFilterOpen(!isFilterOpen);
  };
  const removeFilterHandler = () => {
    setRemoved(!isRemoved);
  };
  const downloadPDF = async (event) => {
    event.preventDefault();
    setPdfLoading(true);
    const { buyers } = await getInvestors(selectedCompany?.id, true);
    const blob = await pdf(
      <BuyerPDF data={buyers} company={selectedCompany?.company_name} />
    ).toBlob();
    setPdfLoading(false);
    saveAs(blob, selectedCompany?.company_name + " Investors.pdf");
  };
  const downloadCsv = () => {
    setCsvLoading(true);
    getInvestors(selectedCompany?.id, true).then(({ buyers }) => {
      setselectedInvestors(buyerListMauplator(buyers));
      setCsvLoading(false);
      downloadCsvBtn?.current?.link.click();
    });
  };
  useEffect(() => {
    init();
  }, [data]);

  const init = () => {
    let list = [];
    data.map((item, i) => {
      const d = item?.programx_output;
      list.push({
        key: i,
        avatar: d.company_logo,
        name: d.company_name,
        // lead_name: d.lead_name,
        score: d.score,
        type: d.type,
        // state: d.state,
        report: d.bar_chart,
        action: "",
      });
    });
    setInvestors(list);
  };
  const onSelectChange = (rows) => {
    onChange(rows);
  };

  const columns = [
    Table.SELECTION_COLUMN,
    {
      title: "",
      dataIndex: "",
      className: "detail-col",
      render: (item, index) => {
        return (
          <div
            className="space-align-container"
            onClick={() => onSelectChange([item.key])}
          >
            <InvestorDetailCol data={item} />
          </div>
        );
      },
    },
    {
      className: "report-col",
      title: "",
      dataIndex: "",
      render: (data) => {
        if (!data.report) return null;
        return (
          <ProgressBar
            data={data.report}
            score={data.score}
            onClick={() => onSelectChange([data.key])}
          />
        );
      },
    },
  ];
  const isBtnDisabled = data?.length < 1;
  const isFilterView = selectedFilter || (investors && investors.length > 0);
  return (
    <React.Fragment>
      <div className="flex flex-row justify-between items-center">
        <Title level={5} style={{ margin: 0 }}>
          Investors
        </Title>
        <div className="search-input investor-search-input">
          <Input
            value={search}
            size={90}
            placeholder="Search by name"
            prefix={<SearchOutlined />}
            onChange={(e) => setSearch(e.target.value)}
          />
          <Button
            className="download-btn"
            disabled={isBtnDisabled || isPdfLoading}
            onClick={downloadPDF}
            style={{ marginLeft: 10 }}
          >
            {isPdfLoading ? "Loading" : "Download PDF"}
          </Button>
          <Button
            className="download-btn"
            onClick={downloadCsv}
            disabled={isBtnDisabled || isCsvLoading}
          >
            {isCsvLoading ? "Loading" : "Download CSV"}
          </Button>
          <div style={{ display: "none" }}>
            <CSVLink
              data={selectedInvestors}
              ref={downloadCsvBtn}
              filename={selectedCompany?.company_name + " Investors"}
              headers={CSV_HEADERS}
            />
          </div>
        </div>
      </div>
      <Card className="buyers-list">
        {investors && investors.length >= 1 && <h5>Conceptor Rank</h5>}
        <Table
          size="middle"
          className="no-thead "
          rowSelection={{
            type: "radio",
            selectedRowKeys: state.selectedRowKeys,
            onChange: onSelectChange,
          }}
          columns={columns}
          dataSource={investors}
          pagination={{ ...pagination, onChange: pageChanger }}
          showSizeChanger={false}
        />
        <div
          className="flex"
          style={{ justifyContent: "space-between", marginTop: 5 }}
        >
          {isFilterView && (
            <div>
              <Button
                onClick={filterToggle}
                variant="contained"
                size="large"
                style={{
                  color: "#fff",
                  background: "#605BFF",
                  borderRadius: 8,
                }}
              >
                Filter
              </Button>
              {selectedFilter && (
                <Button
                  onClick={removeFilterHandler}
                  variant="contained"
                  size="large"
                  style={{
                    // color: "#fff",
                    // background: "#605BFF",
                    borderRadius: 8,
                    marginLeft: 10,
                  }}
                >
                  Reset Filter
                </Button>
              )}
            </div>
          )}
          {investors && investors.length > 0 && (
            <Button
              variant="contained"
              size="large"
              onClick={onAdd}
              style={{
                color: "#fff",
                background: "#605BFF",
                borderRadius: 8,
              }}
            >
              Add to Leads Manager
            </Button>
          )}
        </div>
      </Card>
      <FilterDrawer
        isOpen={isFilterOpen}
        toggle={filterToggle}
        isRemoved={isRemoved}
        setFilter={setFilters}
      />
    </React.Fragment>
  );
};

export default InvestorsList;
