import { replaceValueInDataSet } from "../utils";

export const buyerDataMauplator = (data) => {
  let buyer = {};
  buyer["name"] = data?.company_name
    ? replaceValueInDataSet(data?.company_name)
    : "-";
  buyer["website"] = data?.url ? replaceValueInDataSet(data?.url) : "-";
  buyer["goals"] =
    data?.misc_synergies && data?.misc_synergies?.length >= 1
      ? data?.misc_synergies.join(", ")
      : "-";
  buyer["motivations"] =
    data?.operating_synergies && data?.operating_synergies?.length >= 1
      ? data?.operating_synergies.join(", ")
      : "-";
  buyer["synergies"] =
    data?.financial_synergies && data?.financial_synergies?.length >= 1
      ? data?.financial_synergies.join(", ")
      : "-";
  return buyer;
};

export const buyerListMauplator = (list) => {
  let buyers = [];
  list.map((item) => {
    buyers.push(buyerDataMauplator(item?.programx_output));
  });
  return buyers;
};

export const leadDataMauplator = (data) => {
  let lead = {};
  lead["name"] = data?.lead_name ? replaceValueInDataSet(data?.lead_name) : "-";
  lead["website"] = data?.url ? replaceValueInDataSet(data?.url) : "-";
  lead["status"] = data?.status ? replaceValueInDataSet(data?.status) : "-";
  lead["goals"] =
    data?.recommended_lead_notes?.misc_synergies &&
    data?.recommended_lead_notes?.misc_synergies?.length >= 1
      ? data?.recommended_lead_notes?.misc_synergies.join(", ")
      : "-";
  lead["motivations"] =
    data?.recommended_lead_notes?.operating_synergies &&
    data?.recommended_lead_notes?.operating_synergies?.length >= 1
      ? data?.recommended_lead_notes?.operating_synergies.join(", ")
      : "-";
  lead["synergies"] =
    data?.recommended_lead_notes?.financial_synergies &&
    data?.recommended_lead_notes?.financial_synergies?.length >= 1
      ? data?.recommended_lead_notes?.financial_synergies.join(", ")
      : "-";
  return lead;
};

export const leadListManipulator = (list) => {
  let leads = [];
  list.map((item) => {
    leads.push(leadDataMauplator(item));
  });
  return leads;
};
