// Carousel.js
import { useState, useEffect, useRef } from "react";
import Slider from "react-slick";
import CarouselIcon from "../../../../../assets/images/carousel-arrow.png";
import NoDataIcon from "../../../../../assets/svgs/no-data.svg";
import { fetchPrivateImage } from "../../../../../helpers/fetchImage";
import { BounceLoader } from "react-spinners";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Col, Row } from "antd";
import SourceModal from "../../../../../components/source-modal";

const InvestorLogoBox = ({ data }) => {
  const [isLoading, setLoading] = useState(true);
  const [logo, setLogo] = useState(null);
  useEffect(() => {
    setLoading(true);
    fetchPrivateImage(data.target_logo)
      .then((res) => {
        setLogo(res);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, [data]);
  return (
    <div className="titleWrap">
      <span className="titleImg">
        {isLoading ? (
          <BounceLoader size={10} color="rgb(234, 187, 254)" />
        ) : (
          <img src={logo} alt={data.name} />
        )}
      </span>
      <h2>{data.name}</h2>
    </div>
  );
};

export default function Carousel({ activities = [], activitiesCompany = [] }) {
  const [selectedData, setselectedData] = useState(null);
  const [sourcePreview, setsourcePreview] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [logo, setLogo] = useState(null);

  const sliderRef = useRef(null);

  const sliderSettings = {
    // removes default buttons
    slidesToScroll: 1,
    infinite: false,
    // centerMode: true,
    slidesToShow: 1,
    dots: true,
    dotsClass: `slick-dots`,
    variableWidth: true,
    centerPadding: "0",
    appendDots: (dots) => (
      <div>
        <span
          onClick={() => {
            sliderRef?.current?.slickPrev();
          }}
          className="slider-btn"
        >
          <img src={CarouselIcon} alt="" />
        </span>
        {dots}
        <span
          onClick={() => {
            sliderRef?.current?.slickNext();
          }}
          className="slider-btn next"
        >
          <img src={CarouselIcon} alt="" />
        </span>
      </div>
    ),
    arrows: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          // arrows: false,
          // centerMode: true,
          centerPadding: "40px",
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          // arrows: false,
          // centerMode: true,
          centerPadding: "40px",
          slidesToShow: 1,
        },
      },
    ],
  };

  const handleSourcePreview = (data) => {
    let sources = [];
    if (data.source) {
      sources = data.source.split(",");
    }
    setselectedData(sources);
    setsourcePreview(true);
  };

  useEffect(() => {
    if (activities.length === 0) return;
    setLoading(true);
    fetchPrivateImage(activities[0]?.logo)
      .then((res) => {
        setLogo(res);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, [activities]);
  return (
    <div className="activities-content">
      {activities.length !== 0 ? (
        <>
          <div className="companyTitleWrap">
            {isLoading ? (
              <BounceLoader size={13} color="rgb(234, 187, 254)" />
            ) : (
              <img
                src={logo}
                className="companyTitleImg"
                // alt={activitiesCompany.company_name}
              />
            )}
            {/* {logo && (
              <img
                src={logo}
                className="companyTitleImg"
                alt={activitiesCompany.company_name}
              />
            )} */}
          </div>
          <Slider
            ref={sliderRef}
            {...sliderSettings}
            className={"customSlider"}
          >
            {/* {[...activities, tempdata].map((card, index) => ( */}
            {activities.map((card, index) => (
              <div
                key={index}
                className="mainSlideWrap"
                style={{ backgroundColor: "blue" }}
              >
                <div className="companyTitle" />
                <div className="innerInfoWrap">
                  <InvestorLogoBox data={card} />
                  <div className="infoBar-wrapper">
                    {/* <div className="infoBar title">
                      <span>{card.amount && `${card.amount}m`}</span>
                      <p
                        className={`status ${
                          card.status?.toLowerCase() === "completed"
                            ? "completed"
                            : ""
                        }`}
                      >
                        {card.status}
                      </p>
                    </div> */}
                    <Row gutter={[8, 0]}>
                      <Col xs={12}>
                        <div className="infoBar">
                          <span>{card.transaction_type}</span>
                        </div>
                      </Col>
                      <Col xs={12}>
                        <div className="infoBar">
                          <span>{card.date_of_transaction}</span>
                        </div>
                      </Col>
                      <Col xs={12}>
                        <div className="infoBar">
                        <span>{card.amount}</span>
                        </div>
                      </Col>
                      <Col xs={12}>
                        <div className="infoBar">
                          <span
                            className={`status ${
                              card.status?.toLowerCase() === "completed"
                                ? "completed"
                                : ""
                            }`}
                          >
                            {card.status}
                          </span>
                        </div>
                      </Col>
                    </Row>
                    <button onClick={() => handleSourcePreview(card)}>
                      Source
                    </button>
                    {/* </Col>
                    </Row> */}
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </>
      ) : (
        <>
          <div className="no-activities-data">
            <img src={NoDataIcon} alt="no-data" />
            No Activity Found
          </div>
        </>
      )}

      <SourceModal
        show={sourcePreview}
        sources={selectedData}
        onClose={() => setsourcePreview(false)}
      />
    </div>
  );
}
