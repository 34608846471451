import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Row, Col, Card, Typography } from "antd";
import "../../../../assets/css/index.css";
import CompanyAttributes from "./pages/attributes";
import FirstTimeModal from "./components/modal";
import EbitdaContent from "./pages/ebitda";
import IntrinsicContent from "./pages/intrinsic";
import ExtrinsicContent from "./pages/extrinsic";
import { BeatLoader } from "react-spinners";
import moment from "moment";
import { saleThreeSuggestions, saleTwoSuggestions } from "../../../../constant";
const { Title } = Typography;

const LeadsDashboard = () => {
  const { http } = global.services;
  const company = useSelector((state) => state.CompanyReducer.selected_company);
  const [modal, showModal] = useState(false);
  const [modalData, setmodalData] = useState(null);
  const [attributes, setAttributes] = useState({});
  const [isLoading, setLoading] = useState(false);
  const [state, setState] = useState({
    tree: [],
  });
  const modalPreview = !!+localStorage.getItem("modal");
  useEffect(() => {
    company && init();
  }, [company]);

  useEffect(() => {
    Object.keys(attributes).length && initGoals();
  }, [attributes]);

  const init = async () => {
    const { user_input } = company;
    if (user_input && user_input.length > 0) {
      let attr = {};
      user_input.map((u, i) => {
        Object.keys(u).map((k) => {
          if (i == 0 && u[k].length > 1) attr[k] = u[k][1];
          else if (u[k].length > 0) {
            attr[k] = u[k];
          } else {
            attr[k] = u[k][0];
          }
        });
      });
      setAttributes(attr);
    } else {
      setAttributes({});
      setState({ ...state, tree: [] });
    }
  };

  const initGoals = async () => {
    let tree = [];
    setLoading(true);
    let { data } = await http.get("question");
    setLoading(false);
    const { goals } = data.data;

    goals.forEach((g) => {
      let isValidNumber = typeof attributes[g.key] === "number";
      let isValidString =
        typeof attributes[g.key] === "string" && attributes[g.key] !== "none";
      let isValidObject =
        typeof attributes[g.key] === "object" &&
        attributes[g.key].length > 0 &&
        !attributes[g.key].includes("none");
      if (isValidNumber || isValidString || isValidObject) {
        let goal = g;
        let value =
          typeof attributes[g.key] === "object"
            ? attributes[g.key].join(", ")
            : attributes[g.key];

        if (goal.type === "currency") {
          value = "$" + formatValue(value);
        } else if (goal.type === "percentage") {
          value = value + "%";
        }

        goal.value = value;
        tree.push(goal);
      }
    });

    // if (attributes['question-one'] === 'Recapitalize') {
    //     goals.map(g => {
    //         if (['question-one', 'recap-one', 'recap-two'].includes(g.key)) {
    //             let goal = g
    //             goal.value = attributes[g.key]
    //             tree.push(goal)
    //         }
    //     })
    // } else {
    //     goals.map(g => {
    //         if (['question-one', 'sale-one'].includes(g.key)) {
    //             let goal = g
    //             goal.value = attributes[g.key]
    //             tree.push(goal)
    //         }
    //     })
    // }

    setState({ ...state, tree: tree });
  };

  const formatValue = (val) => {
    let value = Number(val);
    if (value >= 1000000) {
      if (value % 1000000 === 0) {
        val = value / 1000000 + "mm";
      } else {
        val = parseFloat(value / 1000000).toFixed(2) + "mm";
      }
    } else {
      val = value.toLocaleString();
    }

    return val;
  };

  const firModalHeader = (
    <div className="firstModalHeader">
      <div>Conceptor {modalData?.version_number}</div>
      <div className="modalColor" style={{ fontSize: "15px" }}>
        {moment(modalData?.version_date).format("M/D/YYYY")}
      </div>
    </div>
  );

  useEffect(() => {
    if (!modalPreview) return;
    http
      .get("version-update")
      .then(({ data }) => {
        const preview = data?.updates?.length > 0 || data?.bugs.length > 0;
        setmodalData(data);
        if (preview) showModal(true);
      })
      .catch(() => {});
  }, [modalPreview]);

  return (
    <React.Fragment>
      <strong>
        <h2 className="dashboard-header">Home</h2>
      </strong>
      <div className="spacer"></div>
      <Row gutter={[16, 32]}>
        <Col span={13}>
          <React.Fragment>
            <Card bordered={false} className="home-card">
              <Title level={4}>{company.company_name || "Company Name"}</Title>
              <p className="company-des">{attributes?.description_input}</p>
            </Card>
          </React.Fragment>
        </Col>
        <Col span={11}>
          <div className="flex flex-row" style={{ gap: 16 }}>
            <div className="flex-1">
              <Card bordered={false} className="home-card">
                <EbitdaContent attributes={attributes} />
              </Card>
            </div>
            <div className="flex-1">
              <Card bordered={false} className="home-card">
                <IntrinsicContent attributes={attributes} />
              </Card>
            </div>
            <div className="flex-1">
              <Card bordered={false} className="home-card">
                <ExtrinsicContent attributes={attributes} />
              </Card>
            </div>
            {/* <div className='flex-1'>
                            <Card bordered={false}>
                                <Report attributes={attributes} />
                            </Card>
                        </div> */}
          </div>
        </Col>
      </Row>
      <div className="spacer-lg"></div>
      <Row gutter={[16, 32]}>
        <Col span={13}>
          {/* {company && company.company_name &&
                        <React.Fragment>
                            <Title level={5}>{company.company_name || 'Company Name'}</Title>
                        </React.Fragment>
                    } */}
          {/* {attributes.description_input &&
                        <React.Fragment>
                            <Card bordered={false}>
                                {attributes.description_input}
                            </Card>
                            <div className='spacer'></div>
                        </React.Fragment>
                    } */}
          {/* <div className='flex flex-row'>
                        <div className='flex-1 space-x'>
                            <Card bordered={false}>
                                <EbitdaContent attributes={attributes} />
                            </Card>
                        </div>
                        <div className='flex-1 space-x'>
                            <Card bordered={false}>
                                <IntrinsicContent attributes={attributes} />
                            </Card>
                        </div>
                        <div className='flex-1 space-x'>
                            <Card bordered={false}>
                                <ExtrinsicContent attributes={attributes} />
                            </Card>
                        </div>
                        <div className='flex-1'>
                            <Card bordered={false}>
                                <Report attributes={attributes} />
                            </Card>
                        </div>
                    </div> */}
          {/* <div className='spacer'></div> */}
          {/* <div className='flex flex-row'>
                        {attributes.vision_input &&
                            <div className='flex-1 space-x'>
                                <Card bordered={false}>
                                    <strong>
                                        <h1>Vision</h1>
                                    </strong>
                                    <p>
                                        {attributes.vision_input}
                                    </p>
                                </Card>
                            </div>
                        }
                        {attributes.mission_input &&
                            <div className='flex-1'>
                                <Card bordered={false}>
                                    <strong>
                                        <h1>Mission</h1>
                                    </strong>
                                    <p>
                                        {attributes.mission_input}
                                    </p>
                                </Card>
                            </div>
                        }
                    </div>
                    <div className='spacer'></div> */}

          <React.Fragment>
            <Card
              bordered={false}
              className="home-card"
              style={{ minHeight: 680 }}
            >
              <Title level={3}>Goals</Title>
              {isLoading ? (
                <div className="loading-wrapper" style={{ height: 300 }}>
                  <BeatLoader color="#605bff" size={20} />
                </div>
              ) : (
                <div style={{ marginTop: 8 }}>
                  {attributes &&
                    attributes["question-one"] &&
                    !attributes["question-one"].includes("none") && (
                      <>
                        {state.tree.map((t) => (
                          <div
                            key={t.question}
                            style={{
                              marginBottom: 12,
                              borderBottom: "1px solid rgba(38, 38, 38, 0.1)",
                            }}
                          >
                            <span className="goal-title">{t.question}</span>{" "}
                            <br />
                            <p className="goal-value">
                              {t.value}
                              {"  "}
                              {t.key === "sale-two"
                                ? `(${saleTwoSuggestions[t.value - 1]})`
                                : t.key === "sale-three"
                                ? `(${saleThreeSuggestions[t.value - 1]})`
                                : ""}
                            </p>
                          </div>
                        ))}
                      </>
                    )}
                </div>
              )}
            </Card>
            <div className="spacer"></div>
          </React.Fragment>
          {/* <div>
                        <Title level={5}>Financials</Title>
                        <div className='flex-1'>
                            <Card bordered={false}>
                                <FinanceReport
                                    attributes={attributes}
                                />
                            </Card>
                        </div>
                    </div> */}
        </Col>
        <Col span={11}>
          <CompanyAttributes attributes={attributes} />
        </Col>
      </Row>
      {modal && (
        <FirstTimeModal
          title={firModalHeader}
          show={modal}
          onClose={() => {
            localStorage.setItem("modal", 0);
            showModal(false);
          }}
          onSubmit={() => {}}
        >
          <i>Patch Notes</i>
          {modalData?.updates.length > 0 && (
            <>
              <h4 className="modalColor">Updates</h4>
              <ul>
                {modalData?.updates.map((update, index) => (
                  <li key={index}>{update}</li>
                ))}
              </ul>
            </>
          )}
          {modalData?.bugs.length > 0 && (
            <>
              <h4 className="modalColor">Bugs</h4>
              <ul>
                {modalData?.bugs.map((bug, index) => (
                  <li key={index}>{bug}</li>
                ))}
              </ul>
            </>
          )}
        </FirstTimeModal>
      )}
    </React.Fragment>
  );
};

export default LeadsDashboard;
