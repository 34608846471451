import { Modal } from "antd";
import NoDataIcon from "../../assets/svgs/no-data.svg";
import "./styles.scss";

const SourceModal = ({ show, sources = [], onClose }) => {
  const data = sources;
  return (
    <Modal
      open={show}
      onCancel={onClose}
      className="source-modal"
      title={null}
      width={600}
      footer={null}
    >
      <h3>Source</h3>
      {data && data?.length >= 1 ? (
        <ul>
          {data.map((item, index) => (
            <li key={index}>
              <span className="index">
                {(index + 1).toString().padStart(2, "0")}
              </span>{" "}
              <a href={item.replace(/[\[\]']/g, '')} target="_blank">
                {item.replace(/[\[\]']/g, '')}
              </a>
            </li>
          ))}
        </ul>
      ) : (
        <div className="no-data">
          <img src={NoDataIcon} alt="No data" />
          <p>No source available</p>
        </div>
      )}
    </Modal>
  );
};

export default SourceModal;
