import React from "react";
import AttribuesThumnail from "../../../../../assets/images/attributes";

const IntrinsicContent = ({ attributes }) => {
  let intrinsic_input =
    attributes.intrinsic_input != "none" && Object.keys(attributes).length > 0
      ? attributes.intrinsic_input
      : 0;
  if (intrinsic_input >= 1000000) {
    if (intrinsic_input % 1000000 === 0) {
      intrinsic_input = intrinsic_input / 1000000 + "mm";
    } else {
      intrinsic_input = parseFloat(intrinsic_input / 1000000).toFixed(2) + "mm";
    }
  } else if (intrinsic_input >= 100000 || intrinsic_input <= -100000) {
    if (intrinsic_input % 1000 === 0) {
      intrinsic_input = intrinsic_input / 1000 + "k";
    } else {
      intrinsic_input = parseFloat(intrinsic_input / 1000).toFixed(2) + "k";
    }
  } else if (intrinsic_input >= 10000 || intrinsic_input <= 10000) {
    if (intrinsic_input % 1000 === 0) {
      intrinsic_input = intrinsic_input / 1000 + "k";
    } else {
      intrinsic_input = parseFloat(intrinsic_input / 1000).toFixed(2) + "k";
    }
  } else if (intrinsic_input >= 1000 || intrinsic_input <= 1000) {
    if (intrinsic_input % 10000 === 0) {
      intrinsic_input = intrinsic_input / 10000 + "k";
    } else {
      intrinsic_input = parseFloat(intrinsic_input / 10000).toFixed(2) + "k";
    }
  }

  const formatValue = (val) => {
    let value = val;

    if (typeof val === "string") {
      value = value ? value.replaceAll(/[mmk]/g, "") : value;
    }

    return value;
  };

  const formatSuffix = (val) => {
    let value = "";

    if (typeof val === "string") {
      value = val.includes("mm") ? "mm" : val.includes("k") ? "k" : "";
    }

    return value;
  };

  return (
    <div className="stats">
      <img className="thumb" src={AttribuesThumnail.Intrinsic} alt="" />
      <h4>Intrinsic <i>({formatSuffix(intrinsic_input)})</i></h4>
      <h2>
        {intrinsic_input.toString().includes("-") && "-"}$
        {formatValue(intrinsic_input.toString().replaceAll("-", ""))}
        {/* {formatSuffix(intrinsic_input)} */}
      </h2>
    </div>
  );
};

export default IntrinsicContent;
