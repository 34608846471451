import Area from "./area.png";
import Descriptor from "./descriptor.png";
import NaicsSub from "./naics-sub.png";
import Naics from "./naics.png";
import Product from "./products.png";
import Services from "./services.png";
import Speciality from "./speciality.png";
import SupplyChain from "./supply-chain.png";
import Workplace from "./workplace.png";
import Ebidta from "./ebidta.png";
import Intrinsic from "./intrinsic.png";
import Extrinsic from "./extrinsic.png";

export default {
  Area,
  Descriptor,
  NaicsSub,
  Naics,
  Product,
  Services,
  Speciality,
  SupplyChain,
  Workplace,
  Ebidta,
  Intrinsic,
  Extrinsic,
};
