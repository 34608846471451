export const LANDING_PAGE_URL = "https://conceptor.ai/";
export const DEFAULT_COMPANY_LOGO = "default_company_logo.svg";
export const SESSION_TIME = 5; // hours

const answersSuggestions = [
  "Don't care",
  "Care a little",
  "Care a little",
  "Care a little",
  "Moderately Care",
  "Moderately Care",
  "Care a lot",
  "Care a lot",
  "Care a lot",
];
export const saleTwoSuggestions = [...answersSuggestions, "Upmost Priority"];
export const saleThreeSuggestions = [
  ...answersSuggestions,
  "Company cannot change",
];

export const BUYER_TYPES_FILTERS = [
  {
    value: "venture capital",
    label: "Venture Capital",
  },
  {
    value: "corporation",
    label: "Corporation",
  },
  {
    value: "private equity",
    label: "Private Equity",
  },
];
export const BUYER_STATES_FILTERS = [
  { label: "International", value: "International" },
  { label: "Alabama", value: "AL" },
  { label: "Alaska", value: "AK" },
  { label: "Arizona", value: "AZ" },
  { label: "Arkansas", value: "AR" },
  { label: "California", value: "CA" },
  { label: "Colorado", value: "CO" },
  { label: "Connecticut", value: "CT" },
  { label: "Delaware", value: "DE" },
  { label: "Florida", value: "FL" },
  { label: "Georgia", value: "GA" },
  { label: "Hawaii", value: "HI" },
  { label: "Idaho", value: "ID" },
  { label: "Illinois", value: "IL" },
  { label: "Indiana", value: "IN" },
  { label: "Iowa", value: "IA" },
  { label: "Kansas", value: "KS" },
  { label: "Kentucky", value: "KY" },
  { label: "Louisiana", value: "LA" },
  { label: "Maine", value: "ME" },
  { label: "Maryland", value: "MD" },
  { label: "Massachusetts", value: "MA" },
  { label: "Michigan", value: "MI" },
  { label: "Minnesota", value: "MN" },
  { label: "Mississippi", value: "MS" },
  { label: "Missouri", value: "MO" },
  { label: "Montana", value: "MT" },
  { label: "Nebraska", value: "NE" },
  { label: "Nevada", value: "NV" },
  { label: "New Hampshire", value: "NH" },
  { label: "New Jersey", value: "NJ" },
  { label: "New Mexico", value: "NM" },
  { label: "New York", value: "NY" },
  { label: "North Carolina", value: "NC" },
  { label: "North Dakota", value: "ND" },
  { label: "Ohio", value: "OH" },
  { label: "Oklahoma", value: "OK" },
  { label: "Oregon", value: "OR" },
  { label: "Pennsylvania", value: "PA" },
  { label: "Rhode Island", value: "RI" },
  { label: "South Carolina", value: "SC" },
  { label: "South Dakota", value: "SK" },
  { label: "Tennessee", value: "TN" },
  { label: "Texas", value: "TX" },
  { label: "Utah", value: "UT" },
  { label: "Vermont", value: "VT" },
  { label: "Virginia", value: "VA" },
  { label: "Washington", value: "WA" },
  { label: "West Virginia", value: "WV" },
  { label: "Wisconsin", value: "WI" },
  { label: "Wyoming", value: "W" },
];

export const INVESTOR_TYPES_FILTERS = [
  {
    value: "Venture Capital",
    label: "Venture Capital",
  },
  {
    value: "Corporation",
    label: "Corporation",
  },
  {
    value: "Private Equity",
    label: "Private Equity",
  },
];
export const INVESTOR_BROAD_SPECIALTY_FILTERS = [
  // { label: "N/A", value: "N/A" },
  { label: "Healthcare Services", value: "Healthcare Services" },
  { label: "Medical Specialities", value: "Medical Specialities" },
  { label: "Healthcare Products", value: "Healthcare Products" },
  { label: "Health Centers", value: "Health Centers" },
  { label: "Healthcare Manufacturing", value: "Healthcare Manufacturing" },
  { label: "Health Sciences", value: "Health Sciences" },
  { label: "Healthcare Technology", value: "Healthcare Technology" },
];
export const INVESTOR_NARROW_SPECIALTY_FILTERS = {
  "Healthcare Services": [
    "Healthcare Staffing",
    "Home Health Care & Residential Nursing",
    "Hospice Care",
    "In Vitro Diagnostics (IVD)",
    "Transdermal Drug Delivery (TDD)",
    "Market Research",
    "Healthcare Insurance",
    "Management Services Organization (MSO)",
    "Medical Waste Disposal",
    "Drug Testing",
    "Government-Sponsored Healthcare Programs",
    "Subrogation",
    "Healthcare Consulting",
    "Research",
    "Healthcare Education",
    "Adult Day Care",
    "Nursing",
    "Ambulance Services",
    "Healthcare Payer Services",
    "Health Plans",
    "Healthcare Solutions",
    "Biorepository",
    "Healthcare Real Estate Development",
  ],
  "Medical Specialities": [
    "Behavioral Health",
    "Cardiology",
    "Chiropractic",
    "Consumer Healthcare",
    "Cosmetic Surgery",
    "Cosmetics",
    "Cytology",
    "Dentistry",
    "Dermatology",
    "Endocrinology (Diabetes)",
    "Eating Disorders",
    "Gastroenterology",
    "Alopecia",
    "Immunology",
    "Infectious Diseases",
    "Neurology",
    "Oncology",
    "Ophthalmology",
    "Physical Therapy",
    "Primary Care",
    "Psychology",
    "Speech-Language Pathology",
    "Urology",
    "Veterinary",
    "Orthodontics",
    "Orthopedics",
    "Applied Behavior Analysis (ABA)",
    "Anesthesiology",
    "Obstetrics",
    "Urogynecology",
    "Gynecology",
    "Forensics",
    "Orthodontistry",
    "Otolaryngology",
    "Pain Management",
    "Optometry",
    "Phlebology",
    "Podiatry",
    "Psychotherapy",
    "Pediatrics",
    "Hepatology",
    "Health & Wellness",
    "Infusion Therapy",
    "Radiology",
    "Plastic Surgery",
    "Cosmetic Surgery",
    "Palliative Care",
    "Weight Management",
    "Surrogacy",
    "Occupational Therapy",
    "Hematology",
    "Nephrology",
    "Acupuncture",
    "Audiology",
    "Andrology",
    "Psychiatry",
    "Sports Medicine",
    "Critical Care",
    "Electroencephalography",
    "Immuno-oncology",
    "Surgery",
    "Oral Surgery",
    "Pain Management",
    "Pulmonology",
    "Vaccines",
    "Internal Medicine",
    "Chronic Care",
    "Clean Medicine",
    "Neonatology",
    "Rheumatology",
    "Physiology",
    "Geriatrics",
    "Disability Care",
    "Senior Care",
    "Fertility",
    "Pathology",
    "Integrated Care",
    "Men's Health",
    "Women's Health",
  ],
  "Healthcare Products": [
    "Healthcare Products",
    "Medical Equipment & Supplies",
    "Medical Devices",
    "Surgical Devices",
    "Surgical Equipment & Supplies",
    "Biological Medical Product/Biologics",
    "Imaging Equipment",
    "Biotechnology Devices",
    "Healthcare Product Distribution",
  ],
  "Health Centers": [
    "Diagnostic & Medical Laboratories",
    "Hospitals",
    "Clinical Laboratory Services",
    "Surgical Centers",
    "Urgent Care Centers",
    "Fertility Centers",
    "Imaging Services",
    "Ambulatory Surgery Centers",
    "Pharmaceutical Distribution Services",
    "Post-Acute Care",
    "Radiation Oncology Services",
    "Pharmacy",
    "Partial Hospitalization Programs (PHP)",
    "Residential Treatment Centers",
    "Outpatient Centers",
    "Inpatient Centers",
    "Assisted Living",
  ],
  "Healthcare Manufacturing": [
    "Biopharmaceutical Manufacturing",
    "Medical Equipment & Supplies Manufacturing",
    "Medical Devices Manufacturing",
    "Surgery Product Manufacturing",
    "Chemical Manufacturing",
    "Pharmaceutical Manufacturing",
    "Drug Manufacturing",
    "Biotechnology Devices Manufacturing",
  ],
  "Health Sciences": [
    "Omics",
    "Clinical Trials",
    "Histology",
    "Pharmaceuticals",
    "Life Sciences",
    "Quality Control & Testing",
    "Drugs",
    "Drug Development",
    "Drug Discovery",
    "Contract Development and Manufacturing Organization (CDMO)",
    "Contract Research Organization (CRO) Laboratory",
    "Contract Assembly & Packaging",
    "Contract Engineering",
    "Supplements",
    "Contract Service Organizations (CSOs)",
    "Drug Delivery",
    "Drug Research",
    "Biopharmaceuticals",
    "Biotechnology",
  ],
  "Healthcare Technology": [
    "Analytics",
    "Software",
    "Healthcare IT (HCIT)",
    "Telehealth",
    "Augmented Reality (A.R.)",
    "Artificial Intelligence (A.I.)",
    "Data",
    "Robotics",
    "Bioinformatics",
    "3D Imaging",
  ],
};

// TEMP WORKAROUND
export const temo_narrow_specialty = () => {
  const options = [];
  Object.keys(INVESTOR_NARROW_SPECIALTY_FILTERS).forEach((key) => {
    return INVESTOR_NARROW_SPECIALTY_FILTERS[key].forEach((item) => {
      options.push({
        label: item,
        value: item,
      });
    });
  });
  return options;
};

export const INVESTOR_NASICS_FILTERS = [
  {
    label: "Offices of Physicians (except Mental Health Specialists)",
    value: "Offices of Physicians (except Mental Health Specialists)",
  },
  {
    label: "Offices of Physicians, Mental Health Specialists",
    value: "Offices of Physicians, Mental Health Specialists",
  },
  { label: "Offices of Dentists", value: "Offices of Dentists" },
  {
    label: "Offices of Chiropractors",
    value: "Offices of Chiropractors",
  },
  {
    label: "Offices of Optometrists",
    value: "Offices of Optometrists",
  },
  {
    label: "Offices of Mental Health Practitioners (except Physicians)",
    value: "Offices of Mental Health Practitioners (except Physicians)",
  },
  {
    label:
      "Offices of Physical, Occupational and Speech Therapists, and Audiologists",
    value:
      "Offices of Physical, Occupational and Speech Therapists, and Audiologists",
  },
  {
    label: "Offices of Podiatrists",
    value: "Offices of Podiatrists",
  },
  {
    label: "Offices of All Other Miscellaneous Health Practitioners",
    value: "Offices of All Other Miscellaneous Health Practitioners",
  },
  {
    label: "Family Planning Centers",
    value: "Family Planning Centers",
  },
  {
    label: "Outpatient Mental Health and Substance Abuse Centers",
    value: "Outpatient Mental Health and Substance Abuse Centers",
  },
  { label: "HMO Medical Centers", value: "HMO Medical Centers" },
  {
    label: "Kidney Dialysis Centers",
    value: "Kidney Dialysis Centers",
  },
  {
    label: "Freestanding Ambulatory Surgical and Emergency Centers",
    value: "Freestanding Ambulatory Surgical and Emergency Centers",
  },
  {
    label: "All Other Outpatient Care Centers",
    value: "All Other Outpatient Care Centers",
  },
  { label: "Medical Laboratories", value: "Medical Laboratories" },
  {
    label: "Diagnostic Imaging Centers",
    value: "Diagnostic Imaging Centers",
  },
  {
    label: "Home Health Care Services",
    value: "Home Health Care Services",
  },
  { label: "Ambulance Services", value: "Ambulance Services" },
  { label: "Blood and Organ Banks", value: "Blood and Organ Banks" },
  {
    label: "All Other Miscellaneous Ambulatory Health Care Services",
    value: "All Other Miscellaneous Ambulatory Health Care Services",
  },
  {
    label: "General Medical and Surgical Hospitals",
    value: "General Medical and Surgical Hospitals",
  },
  {
    label: "Psychiatric and Substance Abuse Hospitals",
    value: "Psychiatric and Substance Abuse Hospitals",
  },
  {
    label: "Specialty (except Psychiatric and Substance Abuse) Hospitals",
    value: "Specialty (except Psychiatric and Substance Abuse) Hospitals",
  },
  {
    label: "Nursing Care Facilities (Skilled Nursing Facilities)",
    value: "Nursing Care Facilities (Skilled Nursing Facilities)",
  },
  {
    label: "Residential Intellectual and Developmental Disability Facilities",
    value: "Residential Intellectual and Developmental Disability Facilities",
  },
  {
    label: "Residential Mental Health and Substance Abuse Facilities",
    value: "Residential Mental Health and Substance Abuse Facilities",
  },
  {
    label: "Continuing Care Retirement Communities",
    value: "Continuing Care Retirement Communities",
  },
  {
    label: "Assisted Living Facilities for the Elderly",
    value: "Assisted Living Facilities for the Elderly",
  },
  {
    label: "Other Residential Care Facilities",
    value: "Other Residential Care Facilities",
  },
  {
    label: "Child and Youth Services",
    value: "Child and Youth Services",
  },
  {
    label: "Services for the Elderly and Persons with Disabilities",
    value: "Services for the Elderly and Persons with Disabilities",
  },
  {
    label: "Other Individual and Family Services",
    value: "Other Individual and Family Services",
  },
  {
    label: "Community Food Services",
    value: "Community Food Services",
  },
  { label: "Temporary Shelters", value: "Temporary Shelters" },
  {
    label: "Other Community Housing Services",
    value: "Other Community Housing Services",
  },
  {
    label: "Emergency and Other Relief Services",
    value: "Emergency and Other Relief Services",
  },
  {
    label: "Vocational Rehabilitation Services",
    value: "Vocational Rehabilitation Services",
  },
  {
    label: "Child Day Care Services",
    value: "Child Day Care Services",
  },
  {
    label: "Surgical and Medical Instrument Manufacturing ",
    value: "Surgical and Medical Instrument Manufacturing ",
  },
  {
    label: "Pharmaceutical Preparation Manufacturing",
    value: "Pharmaceutical Preparation Manufacturing",
  },
  {
    label:
      "Research and Development in Biotechnology (except Nanobiotechnology)",
    value:
      "Research and Development in Biotechnology (except Nanobiotechnology)",
  },
  { label: "Software Publishers", value: "Software Publishers" },
  {
    label: "Other Scientific and Technical Consulting Services",
    value: "Other Scientific and Technical Consulting Services",
  },
  { label: "Veterinary Services", value: "Veterinary Services" },
  {
    label: "Pet Care (except Veterinary) Services",
    value: "Pet Care (except Veterinary) Services",
  },
  {
    label:
      "Research and Development in the Physical, Engineering, and Life Sciences (except Nanotechnology and Biotechnology)",
    value:
      "Research and Development in the Physical, Engineering, and Life Sciences (except Nanotechnology and Biotechnology)",
  },
  {
    label: "Surgical and Medical Instrument Manufacturing",
    value: "Surgical and Medical Instrument Manufacturing",
  },
  {
    label: "Home Healthcare Services",
    value: "Home Healthcare Services",
  },
  {
    label: "Pharmacies and Drug Stores",
    value: "Pharmacies and Drug Stores",
  },
  {
    label: "In-Vitro Diagnostic Substance Manufacturing",
    value: "In-Vitro Diagnostic Substance Manufacturing",
  },
  {
    label: "Direct Health and Medical Insurance Carriers",
    value: "Direct Health and Medical Insurance Carriers",
  },
  {
    label: "Office Administrative Services",
    value: "Office Administrative Services",
  },
  {
    label: "Electronic Computer Manufacturing",
    value: "Electronic Computer Manufacturing",
  },
  {
    label: "Insurance Agencies and Brokerages",
    value: "Insurance Agencies and Brokerages",
  },
  {
    label: "Employment Placement Agencies",
    value: "Employment Placement Agencies",
  },
  {
    label:
      "Administrative Management and General Management Consulting Services",
    value:
      "Administrative Management and General Management Consulting Services",
  },
  {
    label: "All Other Miscellaneous Manufacturing",
    value: "All Other Miscellaneous Manufacturing",
  },
  {
    label: "Home Health Equipment Rental",
    value: "Home Health Equipment Rental",
  },
  {
    label: "Transportation and Warehousing",
    value: "Transportation and Warehousing",
  },
  {
    label: "Hazardous Waste Treatment and Disposal",
    value: "Hazardous Waste Treatment and Disposal",
  },
  { label: "Collection Agencies", value: "Collection Agencies" },
  {
    label: "Data Processing, Hosting, and Related Services",
    value: "Data Processing, Hosting, and Related Services",
  },
  {
    label: "All Other Health and Personal Care Stores",
    value: "All Other Health and Personal Care Stores",
  },
  {
    label: "Other Management Consulting Services",
    value: "Other Management Consulting Services",
  },
  {
    label: "Medical Equipment and Supplies Manufacturing",
    value: "Medical Equipment and Supplies Manufacturing",
  },
  {
    label: "Third Party Administration of Insurance and Pension Funds",
    value: "Third Party Administration of Insurance and Pension Funds",
  },
  {
    label: "Custom Computer Programming Services",
    value: "Custom Computer Programming Services",
  },
  {
    label: "Nursing agencies, primarily providing home nursing services",
    value: "Nursing agencies, primarily providing home nursing services",
  },
  {
    label: "Educational curriculum development services",
    value: "Educational curriculum development services",
  },
  {
    label: "All Other Miscellaneous Textile Product Mills",
    value: "All Other Miscellaneous Textile Product Mills",
  },
  {
    label: "Diet and Weight Reducing Centers",
    value: "Diet and Weight Reducing Centers",
  },
  {
    label:
      "Artificial intelligence research and development laboratories or services",
    value:
      "Artificial intelligence research and development laboratories or services",
  },
  {
    label:
      "Pharmacy Benefit Management and Other Third Party Administration of Insurance and Pension Funds",
    value:
      "Pharmacy Benefit Management and Other Third Party Administration of Insurance and Pension Funds",
  },
  {
    label: "Diagnostic imaging centers (medical)",
    value: "Diagnostic imaging centers (medical)",
  },
  {
    label: "Surgical Appliance and Supplies Manufacturing",
    value: "Surgical Appliance and Supplies Manufacturing",
  },
  {
    label: "Data processing, hosting, and related services",
    value: "Data processing, hosting, and related services",
  },
  {
    label: "Industrial Design Services",
    value: "Industrial Design Services",
  },
  {
    label: "Administration of Public Health Programs",
    value: "Administration of Public Health Programs",
  },
  { label: "Offices of Dentist", value: "Offices of Dentist" },
  {
    label: "Freestanding Ambulatory Surgical and Emergency centres",
    value: "Freestanding Ambulatory Surgical and Emergency centres",
  },
  {
    label: "Pharmacies and drug store",
    value: "Pharmacies and drug store",
  },
  {
    label: "Home health care services",
    value: "Home health care services",
  },
  { label: "Testing laboratories", value: "Testing laboratories" },
  {
    label: "Eye, ear, nose, and throat hospitals",
    value: "Eye, ear, nose, and throat hospitals",
  },
  { label: "Offices of Physicians", value: "Offices of Physicians" },
  {
    label: "Precision Turned Product Manufacturing",
    value: "Precision Turned Product Manufacturing",
  },
  {
    label: "Pharmaceutical Preparation Manufacturing, Clinical Research",
    value: "Pharmaceutical Preparation Manufacturing, Clinical Research",
  },
  {
    label: "All Other Miscellaneous Waste Management Services",
    value: "All Other Miscellaneous Waste Management Services",
  },
  { label: "Fabric Coating Mills", value: "Fabric Coating Mills" },
  {
    label: "Biological Product (except Diagnostic) Manufacturing",
    value: "Biological Product (except Diagnostic) Manufacturing",
  },
  {
    label: "All Other Insurance Related Activities",
    value: "All Other Insurance Related Activities",
  },
  {
    label: "Arch supports, orthopedic, manufacturing",
    value: "Arch supports, orthopedic, manufacturing",
  },
  {
    label: "Diagnostic equipment, electromedical, manufacturing",
    value: "Diagnostic equipment, electromedical, manufacturing",
  },
  {
    label: "Voluntary Health Organizations",
    value: "Voluntary Health Organizations",
  },
  { label: "Optical goods store", value: "Optical goods store" },
  {
    label: "Adult day care centers",
    value: "Adult day care centers",
  },
  {
    label: "Educational Support Services",
    value: "Educational Support Services",
  },
  {
    label: "Dietary supplements, compounded, manufacturing",
    value: "Dietary supplements, compounded, manufacturing",
  },
  {
    label: "Hospice care services, in-home",
    value: "Hospice care services, in-home",
  },
  {
    label: "Dermatological preparations manufacturing",
    value: "Dermatological preparations manufacturing",
  },
  {
    label:
      "Urgent medical care centers and clinics (except hospitals), freestanding",
    value:
      "Urgent medical care centers and clinics (except hospitals), freestanding",
  },
  {
    label: "Gynecologists' offices (e.g., centers, clinics)",
    value: "Gynecologists' offices (e.g., centers, clinics)",
  },
  {
    label: "Gastroenterologists' offices (e.g., centers, clinics)",
    value: "Gastroenterologists' offices (e.g., centers, clinics)",
  },
  {
    label: "Anesthesiologists' offices (e.g., centers, clinics)",
    value: "Anesthesiologists' offices (e.g., centers, clinics)",
  },
  {
    label: "Translation and Interpretation Services",
    value: "Translation and Interpretation Services",
  },
  { label: "Advertising Agencies", value: "Advertising Agencies" },
  {
    label: "Sporting and Recreational Goods and Supplies Merchant Wholesalers",
    value: "Sporting and Recreational Goods and Supplies Merchant Wholesalers",
  },
  {
    label: "All Other Support Services",
    value: "All Other Support Services",
  },
  {
    label: "Pharmaceuticals merchant wholesalers",
    value: "Pharmaceuticals merchant wholesalers",
  },
  {
    label: "Motion Picture and Video Production",
    value: "Motion Picture and Video Production",
  },
  {
    label: "Research and Development in the Social Sciences and Humanities",
    value: "Research and Development in the Social Sciences and Humanities",
  },
  {
    label: "Telephone Answering Services",
    value: "Telephone Answering Services",
  },
  {
    label: "Professional Organizations",
    value: "Professional Organizations",
  },
  {
    label: "Colleges, Universities, and Professional Schools",
    value: "Colleges, Universities, and Professional Schools",
  },
  {
    label: "Other Personal Care Services",
    value: "Other Personal Care Services",
  },
  { label: "Claims Adjusting", value: "Claims Adjusting" },
  {
    label: "Computer Systems Design Services",
    value: "Computer Systems Design Services",
  },
  {
    label: "Electromedical and Electrotherapeutic Apparatus Manufacturing",
    value: "Electromedical and Electrotherapeutic Apparatus Manufacturing",
  },
  {
    label: "Analytical Laboratory Instrument Manufacturing",
    value: "Analytical Laboratory Instrument Manufacturing",
  },
  {
    label: "Other Nonscheduled Air Transportation",
    value: "Other Nonscheduled Air Transportation",
  },
  {
    label: "Dental Equipment and Supplies Manufacturing",
    value: "Dental Equipment and Supplies Manufacturing",
  },
  {
    label: "Pet and Pet Supplies Stores",
    value: "Pet and Pet Supplies Stores",
  },
  {
    label: "Sporting and Athletic Goods Manufacturing",
    value: "Sporting and Athletic Goods Manufacturing",
  },
  {
    label:
      "All Other Miscellaneous Chemical Product and Preparation Manufacturing",
    value:
      "All Other Miscellaneous Chemical Product and Preparation Manufacturing",
  },
  {
    label: "Other Measuring and Controlling Device Manufacturing",
    value: "Other Measuring and Controlling Device Manufacturing",
  },
  {
    label: "Food (Health) Supplement Stores",
    value: "Food (Health) Supplement Stores",
  },
  {
    label: "Ophthalmic Goods Manufacturing",
    value: "Ophthalmic Goods Manufacturing",
  },
  {
    label: "All Other Professional, Scientific, and Technical Services",
    value: "All Other Professional, Scientific, and Technical Services",
  },
  {
    label: "Other Animal Food Manufacturing",
    value: "Other Animal Food Manufacturing",
  },
  {
    label: "Other Accounting Services",
    value: "Other Accounting Services",
  },
  {
    label: "All Other Health and Personal Care Retailers",
    value: "All Other Health and Personal Care Retailers",
  },
  {
    label: "Other Communications Equipment Manufacturing",
    value: "Other Communications Equipment Manufacturing",
  },
  {
    label:
      "Computing Infrastructure Providers, Data Processing, Web Hosting, and Related Services",
    value:
      "Computing Infrastructure Providers, Data Processing, Web Hosting, and Related Services",
  },
  {
    label: "Document Preparation Services",
    value: "Document Preparation Services",
  },
  {
    label: "Marketing Research and Public Opinion Polling",
    value: "Marketing Research and Public Opinion Polling",
  },
  {
    label: "Facilities Support Services",
    value: "Facilities Support Services",
  },
  {
    label: "Professional and Management Development Training",
    value: "Professional and Management Development Training",
  },
  {
    label: "Medicinal and Botanical Manufacturing",
    value: "Medicinal and Botanical Manufacturing",
  },
  {
    label: "Ambulance merchant wholesalers",
    value: "Ambulance merchant wholesalers",
  },
  {
    label: "Sheet Metal Work Manufacturing",
    value: "Sheet Metal Work Manufacturing",
  },
  {
    label: "Ophthalmic Goods Merchant Wholesalers",
    value: "Ophthalmic Goods Merchant Wholesalers",
  },
  {
    label:
      "Pharmacy Benefit Management and Other Third Party Administration of Insurance and Pension Funds.",
    value:
      "Pharmacy Benefit Management and Other Third Party Administration of Insurance and Pension Funds.",
  },
  {
    label:
      "Administration of Human Resource Programs (except Education, Public Health, and Veterans' Affairs Programs)",
    value:
      "Administration of Human Resource Programs (except Education, Public Health, and Veterans' Affairs Programs)",
  },
  {
    label: "Research and Development in Nanotechnology",
    value: "Research and Development in Nanotechnology",
  },
  {
    label: "Pharmacies and Drug Retailers",
    value: "Pharmacies and Drug Retailers",
  },
  { label: "Dental Laboratories", value: "Dental Laboratories" },
  {
    label: "Custom Computer Programing Services",
    value: "Custom Computer Programing Services",
  },
  {
    label: "Freestanding Ambulatory Surgical and Emergency Centres",
    value: "Freestanding Ambulatory Surgical and Emergency Centres",
  },
  {
    label: "Drugs and Druggists' Sundries Merchant Wholesalers",
    value: "Drugs and Druggists' Sundries Merchant Wholesalers",
  },
  {
    label: "Testing Laboratories and Services",
    value: "Testing Laboratories and Services",
  },
  {
    label: "Marketing Consulting Services",
    value: "Marketing Consulting Services",
  },
  { label: "Shoe Retailers", value: "Shoe Retailers" },
  {
    label: "Miscellaneous Intermediation",
    value: "Miscellaneous Intermediation",
  },
  {
    label: "Human Resources Consulting Services",
    value: "Human Resources Consulting Services",
  },
  {
    label: "Dog and Cat Food Manufacturing",
    value: "Dog and Cat Food Manufacturing",
  },
  {
    label: "Couriers and Express Delivery Services",
    value: "Couriers and Express Delivery Services",
  },
  {
    label: "\tComputer Systems Design Services",
    value: "\tComputer Systems Design Services",
  },
  {
    label: "Abrasive Product Manufacturing",
    value: "Abrasive Product Manufacturing",
  },
  {
    label: "Irradiation Apparatus Manufacturing",
    value: "Irradiation Apparatus Manufacturing",
  },
  {
    label: "All Other Business Support Services",
    value: "All Other Business Support Services",
  },
  {
    label: "Security Systems Services (except Locksmiths)",
    value: "Security Systems Services (except Locksmiths)",
  },
  {
    label: "Computer Facilities Management Services",
    value: "Computer Facilities Management Services",
  },
  { label: "Reinsurance Carriers", value: "Reinsurance Carriers" },
  {
    label: "Other Chemical and Allied Products Merchant Wholesalers",
    value: "Other Chemical and Allied Products Merchant Wholesalers",
  },
  {
    label: "Computer Processing and Data Preparation and Processing Services",
    value: "Computer Processing and Data Preparation and Processing Services",
  },
  {
    label: "Food (Health) Supplement Retailers",
    value: "Food (Health) Supplement Retailers",
  },
  {
    label:
      "Computing Infrastricture providers, Data Processing, Web Hosting and Related Services",
    value:
      "Computing Infrastricture providers, Data Processing, Web Hosting and Related Services",
  },
  {
    label:
      "Computing Infrastructure Providers, Data Processing, Web Hosting, and Related Services\t",
    value:
      "Computing Infrastructure Providers, Data Processing, Web Hosting, and Related Services\t",
  },
  {
    label:
      "Computer Infrastructure Providers, Data Processing, Web Hosting, and Related Services",
    value:
      "Computer Infrastructure Providers, Data Processing, Web Hosting, and Related Services",
  },
  { label: "Linen Supply", value: "Linen Supply" },
  {
    label: "\tSurgical Appliance and Supplies Manufacturing",
    value: "\tSurgical Appliance and Supplies Manufacturing",
  },
  {
    label: "Food Service Contractors",
    value: "Food Service Contractors",
  },
  { label: "Laboratory Services", value: "Laboratory Services" },
  {
    label: "Institutional Furniture Manufacturing",
    value: "Institutional Furniture Manufacturing",
  },
  {
    label:
      "Industrial and Commercial Fan and Blower and Air Purification Equipment Manufacturing",
    value:
      "Industrial and Commercial Fan and Blower and Air Purification Equipment Manufacturing",
  },
  {
    label: "Packaging and Labeling Services",
    value: "Packaging and Labeling Services",
  },
  {
    label: "Computer Systems Design Services\t",
    value: "Computer Systems Design Services\t",
  },
  {
    label: "Public Relations Agencies",
    value: "Public Relations Agencies",
  },
  {
    label: "Computer Programming Services",
    value: "Computer Programming Services",
  },
  {
    label: "Refrigerated Warehousing and Storage",
    value: "Refrigerated Warehousing and Storage",
  },
  {
    label: "Elementary and Secondary Schools",
    value: "Elementary and Secondary Schools",
  },
  {
    label: "Other Administrative Services",
    value: "Other Administrative Services",
  },
  {
    label: "All Other Plastics Product Manufacturing",
    value: "All Other Plastics Product Manufacturing",
  },
  {
    label: "Surgical and Medical Instrument Manufacturing\t",
    value: "Surgical and Medical Instrument Manufacturing\t",
  },
  {
    label: "Custom Computer Programming Services ",
    value: "Custom Computer Programming Services ",
  },
  {
    label:
      "Research and Development in the Physical, Engineering, and Life Sciences (except Nanotechnology and Biotechnology) ",
    value:
      "Research and Development in the Physical, Engineering, and Life Sciences (except Nanotechnology and Biotechnology) ",
  },
  {
    label: "Cosmetics, Beauty Supplies, and Perfume Stores industry",
    value: "Cosmetics, Beauty Supplies, and Perfume Stores industry",
  },
  {
    label: "Other Animal Food Manufacturing\t",
    value: "Other Animal Food Manufacturing\t",
  },
  {
    label: "Pet and Pet Supplies Retailers",
    value: "Pet and Pet Supplies Retailers",
  },
  {
    label: "Psychiatric And Substance Abuse Hospitals ",
    value: "Psychiatric And Substance Abuse Hospitals ",
  },
  {
    label:
      "Medical, Dental, and Hospital Equipment and Supplies Merchant Wholesellers",
    value:
      "Medical, Dental, and Hospital Equipment and Supplies Merchant Wholesellers",
  },
  {
    label: "Fitness and Recreational Sports Centers",
    value: "Fitness and Recreational Sports Centers",
  },
  {
    label:
      "Computing Infrastructure Providers, Data Processing, Web Hosting, and Related services",
    value:
      "Computing Infrastructure Providers, Data Processing, Web Hosting, and Related services",
  },
  {
    label: "Rolling Mill and Other Metalworking Machinery Manufacturing",
    value: "Rolling Mill and Other Metalworking Machinery Manufacturing",
  },
  {
    label: "Other Electronic Component Manufacturing",
    value: "Other Electronic Component Manufacturing",
  },
  {
    label: "Special Needs Transportation",
    value: "Special Needs Transportation",
  },
  {
    label: "Other Direct Insurance (except Life, Health, and Medical) Carriers",
    value: "Other Direct Insurance (except Life, Health, and Medical) Carriers",
  },
  {
    label: "All Other Miscellaneous Food Manufacturing",
    value: "All Other Miscellaneous Food Manufacturing",
  },
  {
    label:
      "Computing Infrastructure Providers, data Processing, Web Hosting, and Related services",
    value:
      "Computing Infrastructure Providers, data Processing, Web Hosting, and Related services",
  },
  {
    label: "Testing Laboratories and Services\n",
    value: "Testing Laboratories and Services\n",
  },
  {
    label: "Ophthalmic Goods Manufacturing\n",
    value: "Ophthalmic Goods Manufacturing\n",
  },
  {
    label: "Convention and Trade Show Organizers",
    value: "Convention and Trade Show Organizers",
  },
  {
    label: "Custom Computer Programming Services\n",
    value: "Custom Computer Programming Services\n",
  },
  {
    label: "Plastics Bag and Pouch Manufacturing",
    value: "Plastics Bag and Pouch Manufacturing",
  },
  {
    label: "Architectural Services",
    value: "Architectural Services",
  },
  {
    label: "Other Services Related to Advertising",
    value: "Other Services Related to Advertising",
  },
  {
    label: "Other Activities Related to Real Estate",
    value: "Other Activities Related to Real Estate",
  },
  {
    label: "Pharmaceutical Preparation Manufacturing\n",
    value: "Pharmaceutical Preparation Manufacturing\n",
  },
  {
    label: "Research and Development in Biotechnology",
    value: "Research and Development in Biotechnology",
  },
  {
    label: "Other Fabricated Wire Product Manufacturing",
    value: "Other Fabricated Wire Product Manufacturing",
  },
  {
    label: "Environmental Consulting Services",
    value: "Environmental Consulting Services",
  },
  {
    label: "Other Farm Product Raw Material Merchant Wholesalers",
    value: "Other Farm Product Raw Material Merchant Wholesalers",
  },
  {
    label: "All Other Basic Organic Chemical Manufacturing",
    value: "All Other Basic Organic Chemical Manufacturing",
  },
];
