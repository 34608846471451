import {
  Page,
  Text,
  View,
  Font,
  Image,
  Document,
  StyleSheet,
} from "@react-pdf/renderer";
import PoppinsRegular from "../assets/fonts/poppins/Poppins-Regular.ttf";
import PoppinsMedium from "../assets/fonts/poppins/Poppins-Medium.ttf";
import PoppinsSemiBold from "../assets/fonts/poppins/Poppins-SemiBold.ttf";
import PoppinsBold from "../assets/fonts/poppins/Poppins-Bold.ttf";
import { replaceValueInDataSet } from "../utils";
import Logo from "../assets/images/logo.png";

Font.register({
  family: "PoppinsBold",
  format: "truetype",
  src: PoppinsBold,
});
Font.register({
  family: "PoppinsSemiBold",
  format: "truetype",
  src: PoppinsSemiBold,
});
Font.register({
  family: "PoppinsMedium",
  format: "truetype",
  src: PoppinsMedium,
});
Font.register({
  family: "PoppinsRegular",
  format: "truetype",
  src: PoppinsRegular,
});
const styles = StyleSheet.create({
  page: {
    backgroundColor: "#fff",
    padding: "40px 20px",
  },
  title: {
    textAlign: "center",
    color: "#605BFF",
    fontSize: 20,
    fontFamily: "PoppinsSemiBold",
    marginTop: 20,
    marginBottom: 50,
  },
  logobox: {
    width: 120,
    height: 150,
    position: "absolute",
    left: 0,
    top: 0,
  },
  section: {
    padding: "15px 0",
    borderBottom: "1px solid #c5c5c5",
  },
  subTitle: {
    fontSize: 16,
    fontFamily: "PoppinsSemiBold",
  },
  link: {
    fontSize: 8,
    fontFamily: "PoppinsRegular",
    color: "#605BFF",
    marginBottom: 2,
  },
  description: {
    fontSize: 9,
    fontFamily: "PoppinsRegular",
  },
  spacer: {
    padding: 5,
  },
  subHeading: {
    fontSize: 12,
    fontFamily: "PoppinsSemiBold",
  },
  listitem: {
    position: "relative",
    paddingLeft: 10,
    display: "flex",
  },
  listbullet: {
    position: "absolute",
    left: 0,
    fontSize: 12,
    color: "#605BFF",
  },
  listtext: {
    fontSize: 10,
    fontFamily: "PoppinsRegular",
  },
  topHeader: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    textAlign: "left",
  },
  statusTxt: {
    textTransform: "capitalize",
    padding: "3px 18px",
    marginLeft: 15,
    color: "#fff",
    fontSize: 9,
    fontFamily: "PoppinsRegular",
    borderRadius: 2,
  },
});

export const BuyerPDF = ({
  data = [],
  logo,
  company = "Rothchild Eye Institute",
}) => {
  return (
    <Document>
      {/* {data.length >= 1 && ( */}
      <Page size="A4" style={styles.page}>
        <Image src={Logo} style={styles.logobox} />
        <Text style={styles.title}>{company} Investors</Text>
        {data.map((item, index) => (
          <View style={styles.section} key={index}>
            <Text style={styles.subTitle}>
              {index + 1} -{" "}
              {item?.programx_output?.company_name
                ? replaceValueInDataSet(item?.programx_output?.company_name)
                : "-"}
            </Text>
            <Text style={styles.link}>
              {item?.programx_output?.url
                ? replaceValueInDataSet(item?.programx_output?.url)
                : "-"}
            </Text>
            <Text style={styles.description}>
              {item?.programx_output?.description
                ? replaceValueInDataSet(item?.programx_output?.description)
                : "-"}
            </Text>
            {item?.programx_output?.misc_synergies &&
              item?.programx_output?.misc_synergies?.length >= 1 && (
                <>
                  <View style={styles.spacer} />
                  <Text style={styles.subHeading}>Client’s Goals</Text>
                  {item?.programx_output?.misc_synergies.map((it, ind) => (
                    <View style={styles.listitem} key={ind}>
                      <Text style={styles.listbullet}>{"\u2022  "}</Text>
                      <Text style={styles.listtext}>{it}</Text>
                    </View>
                  ))}
                </>
              )}
            {item?.programx_output?.operating_synergies &&
              item?.programx_output?.operating_synergies?.length >= 1 && (
                <>
                  <View style={styles.spacer} />
                  <Text style={styles.subHeading}>Target's Motivations</Text>
                  {item?.programx_output?.operating_synergies.map((it, ind) => (
                    <View style={styles.listitem} key={ind}>
                      <Text style={styles.listbullet}>{"\u2022  "}</Text>
                      <Text style={styles.listtext}>{it}</Text>
                    </View>
                  ))}
                </>
              )}
            {item?.programx_output?.financial_synergies &&
              item?.programx_output?.financial_synergies?.length >= 1 && (
                <>
                  <View style={styles.spacer} />
                  <Text style={styles.subHeading}>Synergies</Text>
                  {item?.programx_output?.financial_synergies.map((it, ind) => (
                    <View style={styles.listitem} key={ind}>
                      <Text style={styles.listbullet}>{"\u2022  "}</Text>
                      <Text style={styles.listtext}>{it}</Text>
                    </View>
                  ))}
                </>
              )}
          </View>
        ))}
      </Page>
      {/* )} */}
    </Document>
  );
};

export const LeadsPDF = ({
  data = [],
  logo,
  company = "Rothchild Eye Institute",
}) => {
  const generateStatusColor = (status) => {
    let statusColor = "#D0BBFE";
    if (status.toLowerCase() === "communicating") statusColor = "#5B93FF";
    if (status.toLowerCase() === "contacted") statusColor = "#DD97E9";

    return statusColor;
  };

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <Image src={Logo} style={styles.logobox} />
        <Text style={styles.title}>{company} Leads</Text>
        {data.map((item, index) => (
          <View style={styles.section} key={index}>
            <View style={styles.topHeader}>
              <Text style={styles.subTitle}>
                {index + 1} -{" "}
                {item?.lead_name ? replaceValueInDataSet(item?.lead_name) : "-"}
              </Text>
              {/* <View style={styles.leadStatus}> */}
              <Text
                style={[
                  styles.statusTxt,
                  { backgroundColor: generateStatusColor(item?.status) },
                ]}
              >
                {item?.status ? replaceValueInDataSet(item?.status) : "-"}
              </Text>
              {/* </View> */}
            </View>
            <Text style={styles.link}>
              {item?.url ? replaceValueInDataSet(item?.url) : "-"}
            </Text>
            {/* <Text style={styles.description}>
              {item?.programx_output?.description
                ? replaceValueInDataSet(item?.programx_output?.description)
                : "-"}
            </Text> */}
            {item?.recommended_lead_notes?.misc_synergies &&
              item?.recommended_lead_notes?.misc_synergies?.length >= 1 && (
                <>
                  <View style={styles.spacer} />
                  <Text style={styles.subHeading}>Client’s Goals</Text>
                  {item?.recommended_lead_notes?.misc_synergies.map(
                    (it, ind) => (
                      <View style={styles.listitem} key={ind}>
                        <Text style={styles.listbullet}>{"\u2022  "}</Text>
                        <Text style={styles.listtext}>{it}</Text>
                      </View>
                    )
                  )}
                </>
              )}
            {item?.recommended_lead_notes?.operating_synergies &&
              item?.recommended_lead_notes?.operating_synergies?.length >=
                1 && (
                <>
                  <View style={styles.spacer} />
                  <Text style={styles.subHeading}>Target's Motivations</Text>
                  {item?.recommended_lead_notes?.operating_synergies.map(
                    (it, ind) => (
                      <View style={styles.listitem} key={ind}>
                        <Text style={styles.listbullet}>{"\u2022  "}</Text>
                        <Text style={styles.listtext}>{it}</Text>
                      </View>
                    )
                  )}
                </>
              )}
            {item?.recommended_lead_notes?.financial_synergies &&
              item?.recommended_lead_notes?.financial_synergies?.length >=
                1 && (
                <>
                  <View style={styles.spacer} />
                  <Text style={styles.subHeading}>Synergies</Text>
                  {item?.recommended_lead_notes?.financial_synergies.map(
                    (it, ind) => (
                      <View style={styles.listitem} key={ind}>
                        <Text style={styles.listbullet}>{"\u2022  "}</Text>
                        <Text style={styles.listtext}>{it}</Text>
                      </View>
                    )
                  )}
                </>
              )}
          </View>
        ))}
      </Page>
    </Document>
  );
};
