import * as React from "react";

import { Slider } from "antd";
import { saleThreeSuggestions, saleTwoSuggestions } from "../constant";

const Range = ({ value, question, range = [0, 10], onChange, ...props }) => {
  const isSaleTwo = question?.[0]?.includes("price paid for your company");
  const options = isSaleTwo ? saleTwoSuggestions : saleThreeSuggestions;
  const marks = {
    1: (
      <>
        1 <br />
        <i style={{ fontSize: 12, marginLeft: 10 }}>{options[0]}</i>
      </>
    ),
    2: 2,
    3: (
      <>
        3 <br />
        <i style={{ fontSize: 12, marginLeft: 10 }}>{options[2]}</i>
      </>
    ),
    4: 4,
    5: (
      <>
        5 <br />
        <i style={{ fontSize: 12, marginLeft: 130 }}>{options[4]}</i>
      </>
    ),
    6: 6,
    7: 7,
    8: (
      <>
        8 <br />
        <i style={{ fontSize: 12, marginLeft: 10 }}>{options[7]}</i>
      </>
    ),
    9: 9,
    10: (
      <>
        10 <br />
        <i style={{ fontSize: 12,marginTop:5, position:"absolute",right:0, whiteSpace: "nowrap" }}>
          {options[9]}
        </i>
      </>
    ),
  };
  return (
    <Slider
      style={{ marginBottom: 60 }}
      value={value}
      step={value}
      marks={marks}
      min={1}
      max={10}
      defaultValue={value}
      onChange={onChange}
    />
  );
};

export default Range;
