import React, { useEffect, useState } from "react";
import "./styles.scss";
import { Col, Form, Input, Row, Select, Slider } from "antd";
import {
  BUYER_STATES_FILTERS,
  INVESTOR_BROAD_SPECIALTY_FILTERS,
  INVESTOR_NARROW_SPECIALTY_FILTERS,
  INVESTOR_NASICS_FILTERS,
  INVESTOR_TYPES_FILTERS,
  temo_narrow_specialty,
} from "../../../../../../constant";

function SearchBox({ selectedFilter, setFilters }) {
  // const [narrowOptions, setNarrowOptions] = useState([]);
  const narrowOptions = temo_narrow_specialty();

  // CONST VALS
  const [form] = Form.useForm();
  const marks = {
    0: "1",
    100: "1000",
  };

  // HANDLERS
  const sliderFormatter = (value) => (value === 0 ? 1 : value * 10);
  const broadSpecialityHandler = (value) => {
    // const options = [];
    // value?.forEach((val) => {
    //   if (INVESTOR_NARROW_SPECIALTY_FILTERS[val]) {
    //     INVESTOR_NARROW_SPECIALTY_FILTERS[val].forEach((item) => {
    //       options.push({
    //         label: item,
    //         value: item,
    //       });
    //     });
    //   }
    // });
    // setNarrowOptions(options);
  };
  const clearHandler = () => {
    setFilters({});
    form.resetFields();
  };
  const submitHandler = (values) => {
    setFilters(values);
  };

  // HOOKS
  useEffect(() => {
    if (selectedFilter) {
      form.setFieldsValue(selectedFilter);
      if (selectedFilter.broad_specialty) {
        broadSpecialityHandler([selectedFilter.broad_specialty]);
      }
    }
  }, [selectedFilter]);
  return (
    <Form className="search-box" form={form} onFinish={submitHandler}>
      <div className="top-box">
        <h2>Search Dataset</h2>
        <div className="action-box">
          <button className="action-btn submit">Search</button>
          <span className="action-btn clear" onClick={clearHandler}>
            Clear
          </span>
        </div>
      </div>
      <Row className="fields-wrapper" gutter={[12, 12]}>
        <Col xs={24} lg={12}>
          <Form.Item name="name">
            <Input placeholder="Name" />
          </Form.Item>
        </Col>
        <Col xs={24} lg={6}>
          <Form.Item name="description">
            <Input placeholder="Description" />
          </Form.Item>
        </Col>
        <Col xs={24} lg={6}>
          <Form.Item name="state">
            <Select
              showSearch
              mode="multiple"
              placeholder="State"
              options={BUYER_STATES_FILTERS}
              getPopupContainer={(trigger) => trigger.parentNode}
              filterOption={(input, option) =>
                option.label.toLowerCase().includes(input.toLowerCase())
              }
            >
              {BUYER_STATES_FILTERS.map((state, index) => (
                <Select.Option key={index} value={state.value}>
                  {state.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col xs={24} lg={6}>
          <Form.Item name="type">
            <Select
              showSearch
              mode="multiple"
              placeholder="Type"
              options={INVESTOR_TYPES_FILTERS}
              getPopupContainer={(trigger) => trigger.parentNode}
              filterOption={(input, option) =>
                option.label.toLowerCase().includes(input.toLowerCase())
              }
            >
              {INVESTOR_TYPES_FILTERS.map((state, index) => (
                <Select.Option key={index} value={state.label}>
                  {state.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col xs={24} lg={6}>
          <Form.Item name="naics_six_digit_industry">
            <Select
              showSearch
              mode="multiple"
              placeholder="NAICS Industry"
              options={INVESTOR_NASICS_FILTERS}
              getPopupContainer={(trigger) => trigger.parentNode}
              filterOption={(input, option) =>
                option.label.toLowerCase().includes(input.toLowerCase())
              }
            >
              {INVESTOR_NASICS_FILTERS.map((state, index) => (
                <Select.Option key={index} value={state.value}>
                  {state.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col xs={24} lg={6}>
          <Form.Item name="broad_specialty">
            <Select
              showSearch
              mode="multiple"
              placeholder="Broad Specialty"
              options={INVESTOR_BROAD_SPECIALTY_FILTERS}
              onChange={(value) => broadSpecialityHandler(value)}
              getPopupContainer={(trigger) => trigger.parentNode}
              filterOption={(input, option) =>
                option.label.toLowerCase().includes(input.toLowerCase())
              }
            >
              {INVESTOR_BROAD_SPECIALTY_FILTERS.map((state, index) => (
                <Select.Option key={index} value={state.value}>
                  {state.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col xs={24} lg={6}>
          <Form.Item name="narrow_specialty">
            <Select
              showSearch
              mode="multiple"
              placeholder="Narrow Specialty"
              options={narrowOptions}
              getPopupContainer={(trigger) => trigger.parentNode}
              filterOption={(input, option) =>
                option.label.toLowerCase().includes(input.toLowerCase())
              }
            >
              {narrowOptions.map((state, index) => (
                <Select.Option key={index} value={state.value}>
                  {state.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col xs={24} lg={6}>
          <div className="slider-box">
            <label>
              Preferred Ebitda<i> (mm)</i>
            </label>
            <Form.Item name="preferred_ebitda">
              <Slider
                range
                marks={marks}
                tooltip={{
                  formatter: sliderFormatter,
                }}
              />
            </Form.Item>
          </div>
        </Col>
        <Col xs={24} lg={6}>
          <div className="slider-box">
            <label>
              Preferred Revenue<i> (mm)</i>
            </label>
            <Form.Item name="preferred_revenue">
              <Slider
                range
                marks={marks}
                tooltip={{
                  formatter: sliderFormatter,
                }}
              />
            </Form.Item>
          </div>
        </Col>
        <Col xs={24} lg={6}>
          <div className="slider-box">
            <label>
              Preferred EV<i> (mm)</i>
            </label>
            <Form.Item name="preferred_ev">
              <Slider
                range
                marks={marks}
                tooltip={{
                  formatter: sliderFormatter,
                }}
              />
            </Form.Item>
          </div>
        </Col>
        <Col xs={24} lg={6}>
          <div className="slider-box">
            <label>
              Typical Equity Investments<i> (mm)</i>
            </label>
            <Form.Item name="typical_equity_investments">
              <Slider
                range
                marks={marks}
                tooltip={{
                  formatter: sliderFormatter,
                }}
              />
            </Form.Item>
          </div>
        </Col>
      </Row>
    </Form>
  );
}

export default SearchBox;
