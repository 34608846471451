import React, { useState, useEffect } from "react";
import { Row, Col, Card, Typography, Space, Avatar } from "antd";

import { SynergiesAndGoalsData } from "../../../constants/mock-list";

import SignalIcon from "../../../../../assets/svgs/signal";
import FileIcon from "../../../../../assets/svgs/file";
import DollarCurrecyIcon from "../../../../../assets/svgs/dollar-currency";
import NoDataIcon from "../../../../../assets/svgs/no-data.svg";

const { Title, Text } = Typography;

const SynergiesAndGoals = ({ data }) => {
  const [state, setState] = useState({
    operating_synergies: [],
    financial_synergies: [],
    misc_synergies: [],
  });

  useEffect(() => {
    init();
  }, [data]);

  const init = () => {
    if (
      data.operating_synergies ||
      data.financial_synergies ||
      data.misc_synergies
    ) {
      setState({
        operating_synergies: data.operating_synergies,
        financial_synergies: data.financial_synergies,
        misc_synergies: data.misc_synergies,
      });
    } else {
      setState({
        operating_synergies: [],
        financial_synergies: [],
        misc_synergies: [],
      });
    }
  };
  return (
    <React.Fragment>
      <Title level={5}>Reasons For Target</Title>
      <Row gutter={[10, 24]} className="synergies-goal">
        <Col xs={24} md={8}>
          <Card bordered={false}>
            <Avatar
              className="synergies-goal-icon synergies-goal-icon-0"
              shape="square"
              size={50}
              icon={<SignalIcon />}
            />
            <br />
            <strong>
              <span>Client’s Goals</span>
            </strong>
            {state.misc_synergies && state.misc_synergies?.length >= 1 ? (
              <ul style={{ paddingLeft: 15 }}>
                {state.misc_synergies.map((d, i) => (
                  <li key={i}>{d}</li>
                ))}
              </ul>
            ) : (
              <div className="no-data">
                {/* <img src={NoDataIcon} alt="" />
                No Goals Found */}
              </div>
            )}
          </Card>
        </Col>
        <Col xs={24} md={8}>
          <Card bordered={false}>
            <Avatar
              className="synergies-goal-icon synergies-goal-icon-1"
              shape="square"
              size={50}
              icon={<FileIcon />}
            />
            <br />
            <strong>
              <span>Target's Motivations</span>
            </strong>
            {state.operating_synergies &&
            state.operating_synergies?.length >= 1 ? (
              <ul style={{ paddingLeft: 15 }}>
                {state.operating_synergies.map((d, i) => (
                  <li key={i}>{d}</li>
                ))}
              </ul>
            ) : (
              <div className="no-data">
                {/* <img src={NoDataIcon} alt="" />
                No Motivations Found */}
              </div>
            )}
          </Card>
        </Col>
        <Col xs={24} md={8}>
          <Card bordered={false}>
            <Avatar
              className="synergies-goal-icon synergies-goal-icon-2"
              shape="square"
              size={50}
              icon={<DollarCurrecyIcon />}
            />
            <br />
            <strong>
              <span>Synergies</span>
            </strong>
            {state.financial_synergies &&
            state.financial_synergies?.length >= 1 ? (
              <ul style={{ paddingLeft: 15 }}>
                {state.financial_synergies.map((d, i) => (
                  <li key={i}>{d}</li>
                ))}
              </ul>
            ) : (
              <div className="no-data">
                {/* <img src={NoDataIcon} alt="" /> */}
                {/* No Synergies Found */}
              </div>
            )}
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default SynergiesAndGoals;
