import * as React from "react"

const SvgComponent = (props) => (
  <svg
    width={1181}
    height={847}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g opacity={0.25}>
      <path
        d="M1374.8 238.125s-147.41 142.47-264.05 165.357C975.616 430 871.405 256.226 748.281 322.181c-105.944 56.751-76.412 235.834-179.307 300.005-108.209 67.484-213.384-66.668-324.35-5.843-68.788 37.705-148.595 152.015-148.595 152.015"
        stroke="#fff"
      />
      <path
        d="M1492.38 162.106s-147.94 142.611-264.92 165.592c-135.54 26.627-239.915-147.101-363.436-81.039-106.286 56.844-76.803 235.94-180.036 300.202-108.566 67.582-213.938-66.517-325.264-5.595-69.011 37.766-149.128 152.16-149.128 152.16"
        stroke="#EAB8FF"
      />
      <path
        d="M1295.06 283.619s-130.26 94.607-227.61 115.947c-112.79 24.724-187.4-71.906-292.824-24.483-90.713 40.806-77.837 145.533-166.516 190.557-93.26 47.349-171.185-25.616-266.287 17.949-58.954 27.005-131.844 100.371-131.844 100.371"
        stroke="#FDFDFD"
      />
      <path
        d="M1303.99 279.42s-130.39 94.138-227.72 115.518c-112.779 24.771-187.148-70.935-292.611-23.661-90.746 40.677-78.095 144.549-166.817 189.406-93.305 47.175-171.035-25.046-266.174 18.378-58.977 26.918-131.978 99.86-131.978 99.86"
        stroke="#fff"
      />
      <path
        d="M1312.8 274.795s-130.27 94.607-227.61 115.947c-112.791 24.724-187.402-71.906-292.826-24.483-90.713 40.805-77.837 145.533-166.516 190.557-93.259 47.349-171.184-25.616-266.287 17.948-58.953 27.006-131.844 100.372-131.844 100.372"
        stroke="#fff"
      />
      <path
        d="M1321.5 269.758s-130.26 94.607-227.6 115.946c-112.794 24.725-187.405-71.906-292.829-24.482-90.713 40.805-77.837 145.533-166.516 190.557-93.259 47.349-171.184-25.616-266.286 17.948-58.954 27.006-131.845 100.372-131.845 100.372"
        stroke="#fff"
      />
      <path
        d="M1331.55 264.359s-130.44 94.655-227.9 116.026c-112.925 24.761-187.591-71.855-293.147-24.396-90.827 40.836-77.968 145.569-166.76 190.623-93.378 47.382-171.369-25.566-266.591 18.031-59.028 27.026-132.021 100.42-132.021 100.42"
        stroke="#fff"
      />
      <path
        d="M1340.25 259.324s-130.26 94.608-227.61 115.947c-112.788 24.725-187.399-71.906-292.823-24.483-90.713 40.806-77.837 145.534-166.516 190.557-93.259 47.35-171.184-25.616-266.287 17.949-58.953 27.005-131.844 100.371-131.844 100.371"
        stroke="#fff"
      />
      <path
        d="M1348.96 254.287s-130.27 94.607-227.61 115.947c-112.79 24.724-187.402-71.906-292.826-24.483-90.713 40.806-77.837 145.533-166.516 190.557-93.259 47.349-171.184-25.616-266.287 17.949-58.953 27.005-131.844 100.371-131.844 100.371"
        stroke="#fff"
      />
      <path
        d="M1357.99 250.504s-130.26 94.607-227.61 115.947c-112.79 24.724-187.4-71.906-292.824-24.483-90.713 40.805-77.838 145.533-166.516 190.557-93.26 47.349-171.185-25.616-266.287 17.948-58.954 27.006-131.844 100.372-131.844 100.372"
        stroke="#fff"
      />
      <path
        d="M1366.7 245.465s-130.26 94.607-227.61 115.946c-112.79 24.725-187.4-71.905-292.823-24.482-90.713 40.805-77.838 145.533-166.516 190.557-93.26 47.349-171.185-25.616-266.287 17.948C354.51 572.44 281.62 645.806 281.62 645.806"
        stroke="#fff"
      />
      <path
        d="M1376.74 240.068s-130.44 94.655-227.9 116.026c-112.92 24.761-187.59-71.855-293.146-24.396-90.827 40.836-77.968 145.569-166.759 190.623-93.378 47.382-171.37-25.566-266.591 18.031-59.028 27.026-132.022 100.42-132.022 100.42"
        stroke="#fff"
      />
    </g>
  </svg>
)

export default SvgComponent
