import React, { useEffect, useState, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import * as AppActions from "../../../../store/actions/app";
import * as CompanyActions from "../../../../store/actions/company";
import { Button, Card, Modal, Steps } from "antd";
import Questionaire from "./pages/questionaire";
import Financials from "./pages/financials";
import Submission from "./pages/submission";
import { multipleTypes } from "../../constants/input-types";
import { BeatLoader } from "react-spinners";
import FirstTimeModal from "../dashboard/components/modal";
import NoCompanySymbol from "../../../../assets/images/nocompany.png";
import moment from "moment";

const { Step } = Steps;

const CompanySubmissionPage = (props) => {
  const { http } = global.services;

  const submissionError = useSelector(
    (state) => state.AppReducer.submissionError
  );
  const { selected_company: selectedCompany, companies } = useSelector(
    (state) => state.CompanyReducer
  );

  const [dataSet, setDataSet] = useState({});
  const [company, setCompany] = useState(null);
  const [inputs, setInputs] = useState([]);
  const [state, setState] = useState({});
  const [isLoading, setLoading] = useState(false);
  const [modal, showModal] = useState(false);
  const [modalData, setmodalData] = useState(null);

  const [currentStep, setCurrentStep] = useState(0);

  const history = useHistory();
  const dispatch = useDispatch();
  const modalPreview = !!+localStorage.getItem("modal");

  useEffect(() => {
    setInputs([]);
    setState({});
    initQuestions();
  }, [selectedCompany]);

  const initQuestions = async () => {
    setLoading(true);
    let { data } = await http.get("question");
    setLoading(false);
    setDataSet(data.data);
    setCurrentStep(0);
    init(data.data);
  };
  const disableButton = useMemo(
    () =>
      state[
        currentStep == 0
          ? "goals"
          : currentStep == 1
          ? "attributes"
          : "financials"
      ]?.every((obj) =>
        typeof obj?.value == "number" ||
        obj?.question[0]?.includes("optional") ||
        obj?.optional
          ? true
          : obj?.value?.length
      ),
    [state, currentStep]
  );

  const init = (params) => {
    let inp = [];
    let questions = {};

    const { user_input } = selectedCompany;
    if (user_input && user_input.length > 0) {
      Object.keys(params).map((k, index) => {
        inp[index] = {};
        questions[k] = [];
        params[k].map((q, i) => {
          let question = q;
          inp[index][q.key] = ["none"];
          if (index == 0) {
            if (question.parent.length == 0) {
              if (user_input[index][q.key]?.includes("none")) {
                if (multipleTypes.includes(q.type)) {
                  question.value = [];
                }

                questions[k].push(question);
              } else {
                if (multipleTypes.includes(q.type)) {
                  question.value = user_input[index][q.key];
                } else {
                  question.value = user_input[index][q.key][1];
                }

                questions[k].push(question);
              }
            } else {
              if (!user_input[index][q.key].includes("none")) {
                // if (multipleTypes.includes(q.type))
                //     question.value = user_input[index][q.key]
                // else
                //     question.value = user_input[index][q.key][1]
                question.value = user_input[index][q.key][1];
                questions[k].push(question);
              }
            }
          } else {
            if (user_input[index][q.key]?.includes("none")) {
              if (multipleTypes.includes(q.type)) question.value = [];

              questions[k].push(question);
            } else {
              if (multipleTypes.includes(q.type))
                question.value = user_input[index][q.key];
              else question.value = user_input[index][q.key][0];
              questions[k].push(question);
            }
          }
        });
      });
    } else {
      Object.keys(params).map((k, index) => {
        inp[index] = {};
        questions[k] = [];
        params[k].map((q) => {
          let question = q;
          inp[index][q.key] = ["none"];
          if (q.parent.length == 0) {
            questions[k].push(question);
          }
        });
      });
    }

    setState(questions);
    setInputs(inp);
  };

  // const changeInputs = (params) => {
  //     let inps = inputs
  //     let keys = Object.keys(params)

  //     inputs.map((inp, index) => {
  //         Object.keys(inp).map(k => {
  //             if (keys.includes(k)) {
  //                 if (index == 0) {
  //                     if (params[k]) {
  //                         inps[index][k] = ['block', params[k]]
  //                     }
  //                 } else {
  //                     if (params[k]) {
  //                         inps[index][k] = [params[k]]
  //                     }
  //                 }
  //             }
  //         })
  //     })

  //     setInputs(inps)
  // }

  const submit = async () => {
    Object.keys(state).map((k, i) => {
      state[k].map((q) => {
        if (i == 0) {
          if (q.value) {
            inputs[i][q.key] = ["block", q.value];
            // if (multipleTypes.includes(q.type)) {
            //     inputs[i][q.key] = ['block', ...q.value]
            // } else {
            //     inputs[i][q.key] = ['block', q.value]
            // }
          }
        } else {
          if (typeof q.value == "object") {
            inputs[i][q.key] = q.value;
          } else if (q.value) {
            inputs[i][q.key] = [q.value];
          }
        }
      });
    });
    try {
      dispatch(AppActions.setLoader(true));

      let { data } = await http.put("user-company", {
        id: selectedCompany.id,
        company_name: selectedCompany.company_name,
        user_input: inputs,
        user_id: selectedCompany.user_id,
      });
      dispatch(CompanyActions.setSelectedCompany(data.data));
      dispatch(CompanyActions.updateCompany(data.data));
      dispatch(AppActions.setLoader(false));
      if (data.status === "true") {
        history.push("/home");
      } else {
        dispatch(AppActions.setSubmissionError(data.username));
      }
    } catch (err) {
      dispatch(AppActions.setLoader(false));
    }
  };

  const onClickAction = (action) => {
    if (action === "next") {
      setCurrentStep(currentStep + 1);
    } else {
      setCurrentStep(currentStep - 1);
    }
  };

  const firModalHeader = (
    <div className="firstModalHeader">
      <div>Conceptor {modalData?.version_number}</div>
      <div className="modalColor" style={{ fontSize: "15px" }}>
        {moment(modalData?.version_date).format("M/D/YYYY")}
      </div>
    </div>
  );

  useEffect(() => {
    if (!modalPreview) return;
    http
      .get("version-update")
      .then(({ data }) => {
        const preview = data?.updates?.length > 0 || data?.bugs.length > 0;
        setmodalData(data);
        if (preview) showModal(true);
      })
      .catch(() => {});
  }, [modalPreview]);

  return (
    <React.Fragment>
      {selectedCompany && selectedCompany?.id ? (
        <div className="">
          <strong>
            <h2 className="dashboard-header">Company Submission</h2>
          </strong>
          <Submission
            currentCompany={selectedCompany}
            company={company}
            setCompany={setCompany}
          />
        </div>
      ) : (
        <div className="company-submission-add">
          <img src={NoCompanySymbol} height={100} width={100} />
          <strong>
            <h2>Company Submission</h2>
          </strong>
          <Submission
            currentCompany={selectedCompany}
            company={company}
            setCompany={setCompany}
          />
        </div>
      )}
      {!!companies?.length && (
        <>
          <div className="spacer"></div>
          <Steps size="small" current={currentStep}>
            <Step key={"Goals"} title="Goals" />
            <Step key={"Attributes"} title="Attributes" />
            <Step key={"Financials"} title="Financials" />
          </Steps>

          <div className="steps-content">
            {isLoading ? (
              <Card
                bordered={false}
                className="loading-wrapper"
                style={{ height: 300 }}
              >
                <BeatLoader
                  color="#605bff"
                  // loading={loading}
                  size={20}
                />
              </Card>
            ) : (
              <>
                {currentStep === 0 && (
                  <Questionaire
                    title="goals"
                    currentStep={currentStep}
                    questions={dataSet.goals || []}
                    sets={state}
                    dataSet={dataSet}
                    state={state.goals}
                    setState={setState}
                  />
                )}
                {currentStep === 1 && (
                  <Questionaire
                    title="attributes"
                    currentStep={currentStep}
                    questions={dataSet.attributes || []}
                    sets={state}
                    state={state.attributes}
                    setState={setState}
                  />
                )}
                {currentStep === 2 && (
                  <Financials
                    questions={dataSet.financials || []}
                    sets={state}
                    state={state.financials}
                    setState={setState}
                    currentStep={currentStep}
                  />
                  // <Questionaire
                  //     title="financials"
                  //     questions={dataSet.financials || []}
                  //     sets={state}
                  //     state={state.financials}
                  //     setState={setState}
                  // />
                )}
              </>
            )}
          </div>
          {!isLoading && (
            <div className="steps-action">
              <div className="flex-1"></div>
              {selectedCompany && selectedCompany.id && (
                <div>
                  {currentStep > 0 && (
                    <Button
                      className="btn"
                      type="text"
                      style={{ margin: "0 8px" }}
                      onClick={() => onClickAction("prev")}
                    >
                      Back
                    </Button>
                  )}
                  {currentStep < 2 && (
                    <Button
                      className="btn btn-primary"
                      disabled={!disableButton}
                      onClick={() => onClickAction("next")}
                    >
                      Next
                    </Button>
                  )}
                  {currentStep === 2 && (
                    <Button
                      className="btn btn-primary"
                      disabled={!disableButton}
                      type="primary"
                      onClick={submit}
                    >
                      Done
                    </Button>
                  )}
                </div>
              )}
            </div>
          )}
        </>
      )}
      {/* COMPANY SUBMISSION ERROR */}
      <Modal
        title={`Dear ${submissionError}`}
        open={submissionError ? true : false}
        onCancel={() => dispatch(AppActions.setSubmissionError(null))}
        closable={false}
        footer={
          <div style={{ textAlign: "center" }}>
            <Button
              key="cancel"
              onClick={() => dispatch(AppActions.setSubmissionError(null))}
            >
              OK
            </Button>
          </div>
        }
      >
        <p>
          You have run into an error which has been sent to Conceptor's
          development team. We will address this error shortly and reach out to
          you.
        </p>
        <p>
          If you have any further concerns or do not hear from us, please reach
          out to{" "}
          <a href="mailto:internal@conceptor.ai">internal@conceptor.ai</a>
        </p>
      </Modal>

      <FirstTimeModal
        title={firModalHeader}
        show={modal}
        onClose={() => {
          localStorage.setItem("modal", 0);
          showModal(false);
        }}
        onSubmit={() => {}}
      >
        <i>Patch Notes</i>
        {modalData?.updates.length > 0 && (
          <>
            <h4 className="modalColor">Updates</h4>
            <ul>
              {modalData?.updates.map((update, index) => (
                <li key={index}>{update}</li>
              ))}
            </ul>
          </>
        )}
        {modalData?.bugs.length > 0 && (
          <>
            <h4 className="modalColor">Bugs</h4>
            <ul>
              {modalData?.bugs.map((bug, index) => (
                <li key={index}>{bug}</li>
              ))}
            </ul>
          </>
        )}
      </FirstTimeModal>
    </React.Fragment>
  );
};

export default CompanySubmissionPage;
