import React, { useState } from "react";
import { Row, Col, Card, Typography, Button, Modal } from "antd";
import { replaceValueInDataSet } from "../../../../../utils/index";
import InvestorActivity from "../components/investor-activity";
import { BUYER_STATES_FILTERS } from "../../../../../constant";

const { Title } = Typography;

const InvestorProfile = ({ data = {} }) => {
  const [fullProfilePreview, setfullProfilePreview] = useState(false);

  const handleProfilePreview = () => {
    setfullProfilePreview(!fullProfilePreview);
  };
  return (
    <React.Fragment>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "0.5em",
        }}
      >
        <Title level={5} style={{ margin: 0 }}>
          Investor Profile
        </Title>
        <Button
          onClick={handleProfilePreview}
          variant="contained"
          size="large"
          style={{
            color: "#fff",
            background: "#605BFF",
            borderRadius: 8,
          }}
        >
          Full Profile
        </Button>
      </div>
      <Card bordered={false}>
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <div className="investor-profile-container">
              <span>Investor Name</span>
              <div className="investor-profile-content-multiline single-line">
                {replaceValueInDataSet(data.company_name)}
              </div>
            </div>

            <div className="investor-profile-container">
              <span>Narrow Specialty</span>
              <div className="investor-profile-content-multiline single-line">
                {replaceValueInDataSet(data.narrow_specialty)}
              </div>
            </div>

            <div className="investor-profile-container">
              <span>Type</span>
              <div className="investor-profile-content-multiline single-line">
                {replaceValueInDataSet(data.type)}
              </div>
            </div>
          </Col>
          <Col span={12}>
            <div className="investor-profile-container">
              <span>Website</span>

              {replaceValueInDataSet(data.url) === "-" ? (
                <div className="investor-profile-content-multiline single-line">
                  {replaceValueInDataSet(data.url)}
                </div>
              ) : (
                <a
                  href={data.url}
                  target="_blank"
                  referrerPolicy="no-referrer"
                  className="investor-profile-content-multiline single-line"
                >
                  {replaceValueInDataSet(data.url)}
                </a>
              )}
            </div>

            <div className="investor-profile-container">
              <span>NAICS industry</span>
              <div className="investor-profile-content-multiline single-line">
                {replaceValueInDataSet(data.naics_six_digit_industry)}
              </div>
            </div>

            <div className="investor-profile-container">
              <span>State</span>
              <div className="investor-profile-content-multiline single-line">
                {BUYER_STATES_FILTERS.find((x) => x.value === data.state)
                  ?.label ?? "-"}
              </div>
            </div>
          </Col>
        </Row>
        <div className="investor-profile-container">
          <span>Description</span>
          <div className="investor-profile-content-multiline multi-line">
            <span>{replaceValueInDataSet(data.description)}</span>
          </div>
        </div>
      </Card>
      <InvestorActivity data={data} />
      <Modal
        title="Investor Profile"
        visible={fullProfilePreview}
        onCancel={handleProfilePreview}
        width={900}
        footer={null}
      >
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <div className="investor-profile-container">
              <span>Investor Name</span>
              <div className="investor-profile-content-multiline">
                {replaceValueInDataSet(data.company_name)}
              </div>
            </div>

            <div className="investor-profile-container">
              <span>Type</span>
              <div className="investor-profile-content-multiline">
                {replaceValueInDataSet(data.type)}
              </div>
            </div>

            <div className="investor-profile-container">
              <span>Broad Specialty</span>
              <div className="investor-profile-content-multiline">
                {replaceValueInDataSet(data.broad_specialty)}
              </div>
            </div>
          </Col>
          <Col span={12}>
            <div className="investor-profile-container">
              <span>Website</span>
              {replaceValueInDataSet(data.url) === "-" ? (
                <div className="investor-profile-content-multiline">
                  {replaceValueInDataSet(data.url)}
                </div>
              ) : (
                <a
                  href={data.url}
                  target="_blank"
                  referrerPolicy="no-referrer"
                  className="investor-profile-content-multiline"
                >
                  {replaceValueInDataSet(data.url)}
                </a>
              )}
            </div>

            <div className="investor-profile-container">
              <span>NAICS industry</span>
              <div className="investor-profile-content-multiline">
                {replaceValueInDataSet(data.naics_six_digit_industry)}
              </div>
            </div>
            <div className="investor-profile-container">
              <span>Narrow Specialty</span>
              <div className="investor-profile-content-multiline">
                {replaceValueInDataSet(data.narrow_specialty)}
              </div>
            </div>
          </Col>
        </Row>
        <div className="investor-profile-container">
          <span>Description</span>
          <div className="investor-profile-content-multiline">
            {replaceValueInDataSet(data.description)}
          </div>
        </div>
        <div className="investor-profile-container">
          <span>Headquarter Address</span>
          <div className="investor-profile-content-multiline">
            {replaceValueInDataSet(data.hq_address)}
          </div>
        </div>
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <div className="investor-profile-container">
              <div className="investor-profile-container">
                <span>Specialty Interests</span>
                <div className="investor-profile-content-multiline">
                  {replaceValueInDataSet(data.specialty_interests)}
                </div>
              </div>
              <span>Supply Chain Position</span>
              <div className="investor-profile-content-multiline">
                {replaceValueInDataSet(data.supply_chain_position)}
              </div>
            </div>
          </Col>
          <Col span={12}>
            <div className="investor-profile-container">
              <span>Attribute Interests</span>
              <div className="investor-profile-content-multiline">
                {replaceValueInDataSet(data.attribute_interests)}
              </div>
            </div>
            <div className="investor-profile-container">
              <span>Sector Focus</span>
              <div className="investor-profile-content-multiline">
                {replaceValueInDataSet(data.sector_focus)}
              </div>
            </div>
          </Col>
        </Row>
        <div className="investor-profile-container">
          <span>Preferred EBITDA (in Millions)</span>
          <div className="investor-profile-content-multiline">
            {replaceValueInDataSet(data.preferred_ebitda)}
          </div>
        </div>
        <div className="investor-profile-container">
          <span>Preferred Revenue (in Millions)</span>
          <div className="investor-profile-content-multiline">
            {replaceValueInDataSet(data.preferred_revenue)}
          </div>
        </div>
        <div className="investor-profile-container">
          <span>Preferred EV (in Millions)</span>
          <div className="investor-profile-content-multiline">
            {replaceValueInDataSet(data.preferred_ev)}
          </div>
        </div>
        <div className="investor-profile-container">
          <span>Typical Equity Investment (in Millions)</span>
          <div className="investor-profile-content-multiline">
            {replaceValueInDataSet(data.typical_equity_investments)}
          </div>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default InvestorProfile;
