import React from "react";

const ProgressBar = ({ data = [], score,onClick }) => {
  return (
    <div className="progress-bar-wrapper" onClick={onClick}>
      <div className="progress-bar">
        {data.map((d, i) => (
          <div
            key={i}
            className={`progressbar-box ${d.value > 0 ? "active" : ""}`}
          >
            <div
              className="progressbar-col"
              style={{
                width: `${d.value * 10}%`,
                height: 10,
                backgroundColor: d.color,
                borderTopRightRadius: 8,
                borderBottomRightRadius: 8,
                cursor: "pointer",
                maxWidth: "100%",
              }}
            ></div>
            <p className="score">
              {i === 0
                ? "Client’s Goals"
                : i === 1
                ? "Target's Motivations"
                : "Synergies"}
              <span>
                {d.value} <span>point{d.value > 1 ? "s" : ""}</span>
              </span>
            </p>
          </div>
        ))}
        <span className="total-progress">
          {parseInt(score)}
          {/* {data.reduce((item1, item2) => item1 + item2.value, 0)} */}
        </span>
      </div>
    </div>
  );
};

export default ProgressBar;
