import { SET_LOADER, SET_SUBMISSION_ERROR } from "../types/app";

const initialState = {
  loading: false,
  submissionError: null,
};

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_LOADER:
      return {
        ...state,
        loading: payload,
      };
    case SET_SUBMISSION_ERROR:
      return {
        ...state,
        submissionError: payload,
      };
    default:
      return state;
  }
};

export default reducer;
