import React, { useState } from "react";
import { BounceLoader } from "react-spinners";
import { Table } from "antd";
import { BUYER_STATES_FILTERS } from "../../../../../../constant";
import { Link } from "react-router-dom";
import { replaceValueInDataSet } from "../../../../../../utils";
import http from "../../../../../../services/http";
import ModalSuccess from "../../../../../../components/modal/success";
import Button from "@mui/material/Button";
import SuccessCheckIcon from "../../../../../../assets/svgs/success-check";
import "./styles.scss";

function DataTable({
  data = [],
  isLoading,
  total,
  page,
  setPage,
  sortHandler,
}) {
  const [visible, setVisible] = useState(false);
  const [addLoading, setAddLoading] = useState(false);

  const addToLead = async (data) => {
    if (addLoading) return;
    setAddLoading(true);
    let params = {
      user_company_id: 0,
      company_name: data.company_name,
      company_logo: data.company_logo,
      lead_name: data.company_name,
      url: data.url,
      email: data.email,
      contact_number: data.phone_number,
      is_recommended: 1,
      user_notes: "",
      status: "new lead",
      state: data.state_full,
      country: data.country,
      location: data.hq_address,
      recommended_lead_notes: {
        financial_synergies: [],
        misc_synergies: [],
        operating_synergies: [],
      },
    };
    http
      .post(`user-lead`, params)
      .then(() => setVisible(true))
      .catch((err) => console.error("err", err))
      .finally(() => {
        setAddLoading(false);
      });
  };

  const columns = [
    {
      key: "company_name",
      title: "Investor",
      sorter: () => {},
      render: (data) => (
        <Link to={`investors/${data.id}`} className="line-clamp">
          {replaceValueInDataSet(data?.company_name)}
        </Link>
      ),
    },
    {
      key: "url",
      title: "URL",
      sorter: () => {},
      render: (data) => (
        <Link to={`investors/${data.id}`} className="line-clamp">
          {replaceValueInDataSet(data?.url)}
        </Link>
      ),
    },
    {
      key: "description",
      title: "Description",
      sorter: () => {},
      render: (data) => (
        <Link to={`investors/${data.id}`} className="line-clamp large">
          {replaceValueInDataSet(data?.description)}
        </Link>
      ),
    },
    {
      key: "type",
      title: "Type",
      sorter: () => {},
      render: (data) => (
        <Link to={`investors/${data.id}`} className="line-clamp">
          {replaceValueInDataSet(data?.type)}
        </Link>
      ),
    },
    {
      key: "naics_six_digit_industry",
      title: "NAICS Industry",
      sorter: () => {},
      render: (data) => (
        <Link to={`investors/${data.id}`} className="line-clamp">
          {replaceValueInDataSet(data?.naics_six_digit_industry)}
        </Link>
      ),
    },
    {
      key: "state",
      title: "State",
      sorter: () => {},
      render: (data) => {
        const state = BUYER_STATES_FILTERS.find(
          (item) => item.value === data?.state
        );
        return (
          <Link to={`investors/${data.id}`} className="line-clamp">
            {state?.label ?? "-"}
          </Link>
        );
      },
    },
    {
      key: "broad_specialty",
      title: "Broad Specialty",
      sorter: () => {},
      render: (data) => (
        <Link to={`investors/${data.id}`} className="line-clamp">
          {replaceValueInDataSet(data?.broad_specialty)}
        </Link>
      ),
    },
    {
      key: "narrow_specialty",
      title: "Narrow Specialty",
      sorter: () => {},
      render: (data) => (
        <Link to={`investors/${data.id}`} className="line-clamp">
          {replaceValueInDataSet(data?.narrow_specialty)}
        </Link>
      ),
    },
    {
      title: (
        <>
          Preferred Ebitda<i> (mm)</i>
        </>
      ),
      render(data) {
        return (
          <Link to={`investors/${data.id}`} className="line-clamp">
            {replaceValueInDataSet(data?.preferred_ebitda)}
          </Link>
        );
      },
    },
    {
      title: (
        <>
          Preferred Revenue<i> (mm)</i>
        </>
      ),
      render(data) {
        return (
          <Link to={`investors/${data.id}`} className="line-clamp">
            {replaceValueInDataSet(data?.preferred_revenue)}
          </Link>
        );
      },
    },
    {
      title: (
        <>
          Preferred EV<i> (mm)</i>
        </>
      ),
      render(data) {
        return (
          <Link to={`investors/${data.id}`} className="line-clamp">
            {replaceValueInDataSet(data?.preferred_ev)}
          </Link>
        );
      },
    },
    {
      title: (
        <>
          Equity Investment<i> (mm)</i>
        </>
      ),
      render(data) {
        return (
          <Link to={`investors/${data.id}`} className="line-clamp">
            {replaceValueInDataSet(data?.typical_equity_investments)}
          </Link>
        );
      },
    },
    {
      title: "Action",
      render: (data) => (
        <button className="action-btn" onClick={() => addToLead(data)}>
          Add to Lead Manager
        </button>
      ),
    },
  ];
  const sortingHandler = (pagination, filters, sorter) => {
    sortHandler(sorter);
  };

  // CUSTOM COMPONENT
  const noDataBox = () => (
    <div className="no-data-wrapper">
      {isLoading ? (
        <BounceLoader size={22} color="#d0bbfe" />
      ) : (
        <>
          <svg
            width="116"
            height="155"
            viewBox="0 0 116 155"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M84.3395 107.067C77.1473 99.8745 67.3926 95.834 57.2213 95.834C47.05 95.834 38.6101 98.5597 31.4179 105.752"
              stroke="#d0bbfe"
            />
            <circle
              cx="36.0538"
              cy="83.8835"
              r="6.21686"
              fill="#d0bbfe"
              stroke="#d0bbfe"
            />
            <circle
              cx="79.6925"
              cy="83.8835"
              r="6.21686"
              fill="#d0bbfe"
              stroke="#d0bbfe"
            />
            <path
              d="M113.286 32.0038L113.286 32.0039C114.549 33.2661 115.25 34.9775 115.25 36.7446V38.0833H77.6667V0.5H79.0054C80.8037 0.5 82.5146 1.20212 83.776 2.4634C83.7761 2.46346 83.7761 2.46352 83.7762 2.46358L113.286 32.0038ZM67.0208 0.5V40.9948C67.0208 45.2498 70.5002 48.7292 74.7552 48.7292H115.25V147.099C115.25 150.832 112.249 153.833 108.516 153.833H7.23438C3.50147 153.833 0.5 150.832 0.5 147.099V7.23438C0.5 3.50147 3.50147 0.5 7.23438 0.5H67.0208Z"
              stroke="#d0bbfe"
            />
          </svg>
          <h4>No Data Found</h4>
        </>
      )}
    </div>
  );
  const paginatonItemRender = (_, type, originalElement) => {
    if (type === "prev") {
      return (
        <a>
          <svg
            width="16"
            height="12"
            viewBox="0 0 16 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M15 6.75C15.4142 6.75 15.75 6.41421 15.75 6C15.75 5.58579 15.4142 5.25 15 5.25V6.75ZM0.46967 5.46967C0.176777 5.76256 0.176777 6.23744 0.46967 6.53033L5.24264 11.3033C5.53553 11.5962 6.01041 11.5962 6.3033 11.3033C6.59619 11.0104 6.59619 10.5355 6.3033 10.2426L2.06066 6L6.3033 1.75736C6.59619 1.46447 6.59619 0.989593 6.3033 0.696699C6.01041 0.403806 5.53553 0.403806 5.24264 0.696699L0.46967 5.46967ZM15 5.25L1 5.25V6.75L15 6.75V5.25Z"
              fill="#262626"
            />
          </svg>
        </a>
      );
    }
    if (type === "next") {
      return (
        <a>
          <svg
            width="16"
            height="12"
            viewBox="0 0 16 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1 6.75C0.585786 6.75 0.25 6.41421 0.25 6C0.25 5.58579 0.585786 5.25 1 5.25L1 6.75ZM15.5303 5.46967C15.8232 5.76256 15.8232 6.23744 15.5303 6.53033L10.7574 11.3033C10.4645 11.5962 9.98959 11.5962 9.6967 11.3033C9.40381 11.0104 9.40381 10.5355 9.6967 10.2426L13.9393 6L9.6967 1.75736C9.40381 1.46447 9.40381 0.989593 9.6967 0.696699C9.98959 0.403806 10.4645 0.403806 10.7574 0.696699L15.5303 5.46967ZM1 5.25L15 5.25V6.75L1 6.75L1 5.25Z"
              fill="#262626"
            />
          </svg>
        </a>
      );
    }
    return originalElement;
  };
  return (
    <div className="data-table">
      <p className="total">
        Total investors: <span>{total}</span>
      </p>
      <Table
        columns={columns}
        dataSource={isLoading ? [] : data.map((item, index) => ({ ...item, key: index }))}
        onChange={sortingHandler}
        pagination={{
          current: page,
          position: ["bottomCenter"],
          itemRender: paginatonItemRender,
          showSizeChanger: false,
          total: total,
          onChange: (page) => {
            setPage(page);
          },
        }}
        locale={{
          emptyText: noDataBox(),
        }}
        scroll={{
          x: "max-content",
          y: 600,
        }}
      />
      {visible && (
        <ModalSuccess
          className="program-x-modal"
          show={visible}
          onClose={() => setVisible(false)}
        >
          <div className="program-x-modal-success-content">
            <SuccessCheckIcon />
            <h1 className="title">Successfully added</h1>
            <Button
              className="btn btn-primary"
              type="primary"
              onClick={() => setVisible(false)}
            >
              Ok
            </Button>
          </div>
        </ModalSuccess>
      )}
    </div>
  );
}

export default DataTable;
