import React, { useState, useEffect } from "react";
import { Card } from "antd";
import http from "../../../../services/http";
import { useParams, Link } from "react-router-dom";
import { BeatLoader, BounceLoader } from "react-spinners";
import { toast } from "react-toastify";
import { fetchPrivateImage } from "../../../../helpers/fetchImage";
import { replaceValueInDataSet } from "../../../../utils";
import InvestorActivities from "./activities";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import "./styles.scss";

function InvestorDetail() {
  // STATES
  const [isLoading, setLoading] = useState(false);
  const [investor, setInvestor] = useState(null);
  const [investorActivities, setInvestorActivities] = useState([]);
  const [logo, setLogo] = useState(null);
  const [logoLoading, setLogoLoading] = useState(true);

  // CONST VALS
  const { id } = useParams();
  const history = useHistory();

  const goBack = () => {
    history.goBack();
  };

  // HELPERS
  const getInvestorDetail = async () => {
    setLoading(true);
    try {
      const { data } = await http.get(`investor/${id}`);
      setLoading(false);
      setInvestor(data?.investor);
      setInvestorActivities(data?.activities);
    } catch ({ response }) {
      setLoading(false);
      toast.error(response?.data?.error);
    }
  };

  useEffect(() => {
    getInvestorDetail();
  }, [id]);

  useEffect(() => {
    if (!investor) return;
    setLogoLoading(true);
    fetchPrivateImage(investor.company_logo)
      .then((res) => {
        setLogo(res);
        setLogoLoading(false);
      })
      .catch((err) => {
        setLogoLoading(false);
      });
  }, [investor]);

  return (
    <>
      <button className="back-to-dataset" onClick={goBack}>
        <ArrowLeftOutlined /> Back
      </button>
      <h2 className="dashboard-header">Investor Detail</h2>
      <div className="investor-detail">
        {isLoading && (
          <div className="loader-wraper">
            <BeatLoader color="#605bff" />
          </div>
        )}
        {investor && (
          <>
            <Card className="basic-detail">
              <div className="title">
                <span className="thumb">
                  {logoLoading ? (
                    <BounceLoader size={10} color="rgb(234, 187, 254)" />
                  ) : (
                    <img src={logo} alt={investor?.company_name} />
                  )}
                </span>
                <h3>{investor?.company_name}</h3>
              </div>
              <p className="description">
                {replaceValueInDataSet(investor?.description)}
              </p>
              <div className="other-detail">
                <div className="item url">
                  <h5>URL</h5>
                  <a href={investor?.url} target="_blank">
                    <p>{investor?.url}</p>
                  </a>
                </div>
                <div className="item type">
                  <h5>Type</h5>
                  <p>{replaceValueInDataSet(investor?.type)}</p>
                </div>
                <div className="item address">
                  <h5>Address</h5>
                  <p>{replaceValueInDataSet(investor?.hq_address)}</p>
                </div>
                <div className="item naics">
                  <h5>NAICS Industry</h5>
                  <p>
                    {replaceValueInDataSet(investor?.naics_six_digit_industry)}
                  </p>
                </div>
                <div className="item broad">
                  <h5>Broad Specialty</h5>
                  <p>{replaceValueInDataSet(investor?.broad_specialty)}</p>
                </div>
                <div className="item narrow">
                  <h5>Narrow Specialty</h5>
                  <p>{replaceValueInDataSet(investor?.narrow_specialty)}</p>
                </div>
                <div className="item investment">
                  <h5>
                    Typical Investment<i> (mm)</i>
                  </h5>
                  <p>
                    {replaceValueInDataSet(
                      investor?.typical_equity_investments
                    )}
                  </p>
                </div>
                <div className="item ebidta">
                  <h5>
                    Preferred EBTIDA<i> (mm)</i>
                  </h5>
                  <p>{replaceValueInDataSet(investor?.preferred_ebitda)}</p>
                </div>
                <div className="item ev">
                  <h5>
                    Preferred Enterprise Value (EV)<i> (mm)</i>
                  </h5>
                  <p>{replaceValueInDataSet(investor?.preferred_ev)}</p>
                </div>
                <div className="item revenue">
                  <h5>
                    Preferred Revenue<i> (mm)</i>
                  </h5>
                  <p>{replaceValueInDataSet(investor?.preferred_revenue)}</p>
                </div>
                <div className="item sector">
                  <h5>Sector Focus</h5>
                  <p>{replaceValueInDataSet(investor?.sector_focus)}</p>
                </div>
              </div>
            </Card>
            <InvestorActivities activities={investorActivities} />
          </>
        )}
      </div>
    </>
  );
}

export default InvestorDetail;
