import React, { useEffect } from "react";
import { Modal, Button, Form } from "antd";

const FirstTimeModal = ({ title, show, onSubmit, onClose, ...props }) => {
  useEffect(() => {
    document.addEventListener("keypress", (event) => {
    let keyCode = event.code;
      if (keyCode === "Enter") {
      onClose()
    }
    });
  }, [])
  
  return (
    <Modal
      title={title}
      visible={show}
      onCancel={onClose}
      closable={false}
      footer={
        <div style={{ textAlign: "center" }}>
          <Button key="cancel" onClick={onClose}>
            Cancel
          </Button>
          {/* <Button key="submit" onClick={onSubmit}>
          Create
        </Button> */}
        </div>
      }
    >
      {props.children}
    </Modal>
  );
};

export default FirstTimeModal;
