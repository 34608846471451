export const countryState = {
  Australia: [
    "Australian Capital Territory",
    "New South Wales",
    "Northern Territory",
    "Queensland",
    "South Australia",
    "Tasmania",
    "Victoria",
    "Western Australia",
  ],
  Brazil: [
    "Acre",
    "Alagoas",
    "Amapá",
    "Amazonas",
    "Bahia",
    "Ceará",
    "Distrito Federal",
    "Espírito Santo",
    "Goiás",
    "Maranhão",
    "Mato Grosso",
    "Mato Grosso do Sul",
    "Minas Gerais",
    "Pará",
    "Paraíba",
    "Paraná",
    "Pernambuco",
    "Piauí",
    "Rio de Janeiro",
    "Rio Grande do Norte",
    "Rio Grande do Sul",
    "Rondônia",
    "Roraima",
    "Santa Catarina",
    "São Paulo",
    "Sergipe",
    "Tocantins",
  ],
  Canada: [
    "Alberta",
    "British Columbia",
    "Manitoba",
    "New Brunswick",
    "Newfoundland and Labrador",
    "Northwest Territories",
    "Nova Scotia",
    "Nunavut",
    "Ontario",
    "Prince Edward Island",
    "Quebec",
    "Saskatchewan",
    "Yukon",
  ],
  China: [
    "Anhui",
    "Beijing",
    "Chongqing",
    "Fujian",
    "Gansu",
    "Guangdong",
    "Guangxi",
    "Guizhou",
    "Hainan",
    "Hebei",
    "Heilongjiang",
    "Henan",
    "Hong Kong",
    "Hubei",
    "Hunan",
    "Inner Mongolia",
    "Jiangsu",
    "Jiangxi",
    "Jilin",
    "Liaoning",
    "Macau",
    "Ningxia",
    "Qinghai",
    "Shaanxi",
    "Shandong",
    "Shanghai",
    "Shanxi",
    "Sichuan",
    "Tianjin",
    "Tibet",
    "Xinjiang",
    "Yunnan",
    "Zhejiang",
  ],
  France: [
    "Auvergne-Rhône-Alpes",
    "Bourgogne-Franche-Comté",
    "Bretagne",
    "Centre-Val de Loire",
    "Corse",
    "Grand Est",
    "Hauts-de-France",
    "Île-de-France",
    "Normandie",
    "Nouvelle-Aquitaine",
    "Occitanie",
    "Pays de la Loire",
    "Provence-Alpes-Côte d'Azur",
  ],
  India: [
    "Andaman and Nicobar Islands",
    "Andhra Pradesh",
    "Arunachal Pradesh",
    "Assam",
    "Bihar",
    "Chandigarh",
    "Chhattisgarh",
    "Dadra and Nagar Haveli",
    "Daman and Diu",
    "Delhi",
    "Goa",
    "Gujarat",
    "Haryana",
    "Himachal Pradesh",
    "Jammu and Kashmir",
    "Jharkhand",
    "Karnataka",
    "Kerala",
    "Lakshadweep",
    "Madhya Pradesh",
    "Maharashtra",
    "Manipur",
    "Meghalaya",
    "Mizoram",
    "Nagaland",
    "Odisha",
    "Puducherry",
    "Punjab",
    "Rajasthan",
    "Sikkim",
    "Tamil Nadu",
    "Telangana",
    "Tripura",
    "Uttar Pradesh",
    "Uttarakhand",
    "West Bengal",
  ],
  Italy: [
    "Abruzzo",
    "Aosta Valley",
    "Apulia",
    "Basilicata",
    "Calabria",
    "Campania",
    "Emilia-Romagna",
    "Friuli-Venezia Giulia",
    "Lazio",
    "Liguria",
    "Lombardy",
    "Marche",
    "Molise",
    "Piedmont",
    "Sardinia",
    "Sicily",
    "Trentino-South Tyrol",
    "Tuscany",
    "Umbria",
    "Veneto",
  ],
  UK: [
    "Aberdeen",
    "Aberdeenshire",
    "Angus",
    "Antrim",
    "Antrim and Newtownabbey",
    "Ards",
    "Ards and North Down",
    "Argyll and Bute",
    "Armagh City and District Council",
    "Armagh Banbridge and Craigavon",
    "Ascension Island",
    "Ballymena Borough",
    "Ballymoney",
    "Banbridge",
    "Barnsley",
    "Bath and North East Somerset",
    "Bedford",
    "Belfast district",
    "Birmingham",
    "Blackburn with Darwen",
    "Blackpool",
    "Blaenau Gwent County Borough",
    "Bolton",
    "Bournemouth",
    "Bracknell Forest",
    "Bradford",
    "Bridgend County Borough",
    "Brighton and Hove",
    "Buckinghamshire",
    "Bury",
    "Caerphilly County Borough",
    "Calderdale",
    "Cambridgeshire",
    "Carmarthenshire",
    "Carrickfergus Borough Council",
    "Castlereagh",
    "Causeway Coast and Glens",
    "Central Bedfordshire",
    "Ceredigion",
    "Cheshire East",
    "Cheshire West and Chester",
    "City and County of Cardiff",
    "City and County of Swansea",
    "City of Bristol",
    "City of Derby",
    "City of Kingston upon Hull",
    "City of Leicester",
    "City of London",
    "City of Nottingham",
    "City of Peterborough",
    "City of Plymouth",
    "City of Portsmouth",
    "City of Southampton",
    "City of Stoke-on-Trent",
    "City of Sunderland",
    "City of Westminster",
    "City of Wolverhampton",
    "City of York",
    "Clackmannanshire",
    "Coleraine Borough Council",
    "Conwy County Borough",
    "Cookstown District Council",
    "Cornwall",
    "County Durham",
    "Coventry",
    "Craigavon Borough Council",
    "Cumbria",
    "Darlington",
    "Denbighshire",
    "Derbyshire",
    "Derry City and Strabane",
    "Derry City Council",
    "Devon",
    "Doncaster",
    "Dorset",
    "Down District Council",
    "Dudley",
    "Dumfries and Galloway",
    "Dundee",
    "Dungannon and South Tyrone Borough Council",
    "East Ayrshire",
    "East Dunbartonshire",
    "East Lothian",
    "East Renfrewshire",
    "East Riding of Yorkshire",
    "East Sussex",
    "Edinburgh",
    "England",
    "Essex",
    "Falkirk",
    "Fermanagh and Omagh",
    "Fermanagh District Council",
    "Fife",
    "Flintshire",
    "Gateshead",
    "Glasgow",
    "Gloucestershire",
    "Gwynedd",
    "Halton",
    "Hampshire",
    "Hartlepool",
    "Herefordshire",
    "Hertfordshire",
    "Highland",
    "Inverclyde",
    "Isle of Wight",
    "Isles of Scilly",
    "Kent",
    "Kirklees",
    "Knowsley",
    "Lancashire",
    "Larne Borough Council",
    "Leeds",
    "Leicestershire",
    "Limavady Borough Council",
    "Lincolnshire",
    "Lisburn and Castlereagh",
    "Lisburn City Council",
    "Liverpool",
    "London Borough of Barking and Dagenham",
    "London Borough of Barnet",
    "London Borough of Bexley",
    "London Borough of Brent",
    "London Borough of Bromley",
    "London Borough of Camden",
    "London Borough of Croydon",
    "London Borough of Ealing",
    "London Borough of Enfield",
    "London Borough of Hackney",
    "London Borough of Hammersmith and Fulham",
    "London Borough of Haringey",
    "London Borough of Harrow",
    "London Borough of Havering",
    "London Borough of Hillingdon",
    "London Borough of Hounslow",
    "London Borough of Islington",
    "London Borough of Lambeth",
    "London Borough of Lewisham",
    "London Borough of Merton",
    "London Borough of Newham",
    "London Borough of Redbridge",
    "London Borough of Richmond upon Thames",
    "London Borough of Southwark",
    "London Borough of Sutton",
    "London Borough of Tower Hamlets",
    "London Borough of Waltham Forest",
    "London Borough of Wandsworth",
    "Magherafelt District Council",
    "Manchester",
    "Medway",
    "Merthyr Tydfil County Borough",
    "Metropolitan Borough of Wigan",
    "Mid and East Antrim",
    "Mid Ulster",
    "Middlesbrough",
    "Midlothian",
    "Milton Keynes",
    "Monmouthshire",
    "Moray",
    "Moyle District Council",
    "Neath Port Talbot County Borough",
    "Newcastle upon Tyne",
    "Newport",
    "Newry and Mourne District Council",
    "Newry Mourne and Down",
    "Newtownabbey Borough Council",
    "Norfolk",
    "North Ayrshire",
    "North Down Borough Council",
    "North East Lincolnshire",
    "North Lanarkshire",
    "North Lincolnshire",
    "North Somerset",
    "North Tyneside",
    "North Yorkshire",
    "Northamptonshire",
    "Northern Ireland",
    "Northumberland",
    "Nottinghamshire",
    "Oldham",
    "Omagh District Council",
    "Orkney Islands",
    "Outer Hebrides",
    "Oxfordshire",
    "Pembrokeshire",
    "Perth and Kinross",
    "Poole",
    "Powys",
    "Reading",
    "Redcar and Cleveland",
    "Renfrewshire",
    "Rhondda Cynon Taf",
    "Rochdale",
    "Rotherham",
    "Royal Borough of Greenwich",
    "Royal Borough of Kensington and Chelsea",
    "Royal Borough of Kingston upon Thames",
    "Rutland",
    "Saint Helena",
    "Salford",
    "Sandwell",
    "Scotland",
    "Scottish Borders",
    "Sefton",
    "Sheffield",
    "Shetland Islands",
    "Shropshire",
    "Slough",
    "Solihull",
    "Somerset",
    "South Ayrshire",
    "South Gloucestershire",
    "South Lanarkshire",
    "South Tyneside",
    "Southend-on-Sea",
    "St Helens",
    "Staffordshire",
    "Stirling",
    "Stockport",
    "Stockton-on-Tees",
    "Strabane District Council",
    "Suffolk",
    "Surrey",
    "Swindon",
    "Tameside",
    "Telford and Wrekin",
    "Thurrock",
    "Torbay",
    "Torfaen",
    "Trafford",
    "United Kingdom",
    "Vale of Glamorgan",
    "Wakefield",
    "Wales",
    "Walsall",
    "Warrington",
    "Warwickshire",
    "West Berkshire",
    "West Dunbartonshire",
    "West Lothian",
    "West Sussex",
    "Wiltshire",
    "Windsor and Maidenhead",
    "Wirral",
    "Wokingham",
    "Worcestershire",
    "Wrexham County Borough",
  ],
  Germany: [
    "Baden-Württemberg",
    "Bavaria",
    "Berlin",
    "Brandenburg",
    "Bremen",
    "Hamburg",
    "Hesse",
    "Lower Saxony",
    "Mecklenburg-Vorpommern",
    "North Rhine-Westphalia",
    "Rhineland-Palatinate",
    "Saarland",
    "Saxony",
    "Saxony-Anhalt",
    "Schleswig-Holstein",
    "Thuringia",
  ],
  Ukraine: [
    "Autonomous Republic of Crimea",
    "Cherkaska",
    "Chernihivska",
    "Chernivetska",
    "Dnipropetrovska",
    "Donetska",
    "Ivano-Frankivska",
    "Kharkivska",
    "Khersonska",
    "Khmelnytska",
    "Kirovohradska",
    "Kyivska",
    "Luhanska",
    "Lvivska",
    "Mykolaivska",
    "Odeska",
    "Poltavska",
    "Rivnenska",
    "Sumska",
    "Ternopilska",
    "Vinnytska",
    "Volynska",
    "Zakarpatska",
    "Zaporizka",
    "Zhytomyrska",
  ],
  USA: [
    "Alabama",
    "Alaska",
    "Arizona",
    "Arkansas",
    "California",
    "Colorado",
    "Connecticut",
    "Delaware",
    "Florida",
    "Georgia",
    "Hawaii",
    "Idaho",
    "Illinois",
    "Indiana",
    "Iowa",
    "Kansas",
    "Kentucky",
    "Louisiana",
    "Maine",
    "Maryland",
    "Massachusetts",
    "Michigan",
    "Minnesota",
    "Mississippi",
    "Missouri",
    "Montana",
    "Nebraska",
    "Nevada",
    "New Hampshire",
    "New Jersey",
    "New Mexico",
    "New York",
    "North Carolina",
    "North Dakota",
    "Ohio",
    "Oklahoma",
    "Oregon",
    "Pennsylvania",
    "Rhode Island",
    "South Carolina",
    "South Dakota",
    "Tennessee",
    "Texas",
    "Utah",
    "Vermont",
    "Virginia",
    "Washington",
    "West Virginia",
    "Wisconsin",
    "Wyoming",
  ],
};

export const countryStateAbbrev = {
  Australia: ["ACT", "NSW", "NT", "QLD", "SA", "TAS", "VIC", "WA"],
  Brazil: [
    "AC",
    "AL",
    "AP",
    "AM",
    "BA",
    "CE",
    "DF",
    "ES",
    "GO",
    "MA",
    "MT",
    "MS",
    "MG",
    "PA",
    "PB",
    "PR",
    "PE",
    "PI",
    "RJ",
    "RN",
    "RS",
    "RO",
    "RR",
    "SC",
    "SP",
    "SE",
    "TO",
  ],
  Canada: [
    "AB",
    "BC",
    "MB",
    "NB",
    "NL",
    "NT",
    "NS",
    "NU",
    "ON",
    "PE",
    "QC",
    "SK",
    "YT",
  ],
  China: [
    "AH",
    "BJ",
    "CQ",
    "FJ",
    "GS",
    "GD",
    "GX",
    "GZ",
    "HI",
    "HE",
    "HL",
    "HA",
    "HB",
    "HK",
    "HN",
    "JL",
    "JS",
    "JX",
    "LN",
    "MO",
    "NX",
    "QH",
    "SN",
    "SD",
    "SH",
    "SX",
    "SC",
    "TJ",
    "XZ",
    "XJ",
    "YN",
    "ZJ",
  ],
  France: [
    "ARA",
    "BFC",
    "BRE",
    "CVL",
    "COR",
    "GES",
    "HDF",
    "IDF",
    "NOR",
    "NAQ",
    "OCC",
    "PDL",
    "PAC",
  ],
  India: [
    "AN",
    "AP",
    "AR",
    "AS",
    "BR",
    "CH",
    "CT",
    "DN",
    "DD",
    "DL",
    "GA",
    "GJ",
    "HR",
    "HP",
    "JK",
    "JH",
    "KA",
    "KL",
    "LD",
    "MP",
    "MH",
    "MN",
    "ML",
    "MZ",
    "NL",
    "OR",
    "PY",
    "PB",
    "RJ",
    "SK",
    "TN",
    "TS",
    "TR",
    "UP",
    "UK",
    "WB",
  ],
  Italy: [
    "65",
    "23",
    "75",
    "77",
    "78",
    "72",
    "45",
    "36",
    "62",
    "42",
    "25",
    "57",
    "67",
    "21",
    "88",
    "82",
    "32",
    "52",
    "55",
    "34",
  ],
  UK: [
    "ABE",
    "ABD",
    "ANS",
    "ANT",
    "ANN",
    "ARD",
    "AND",
    "AGB",
    "ARM",
    "ABC",
    "SH-AC",
    "BLA",
    "BLY",
    "BNB",
    "BNS",
    "BAS",
    "BDF",
    "BFS",
    "BIR",
    "BBD",
    "BPL",
    "BGW",
    "BOL",
    "BMH",
    "BRC",
    "BRD",
    "BGE",
    "BNH",
    "BKM",
    "BUR",
    "CAY",
    "CLD",
    "CAM",
    "CMN",
    "CKF",
    "CSR",
    "CCG",
    "CBF",
    "CGN",
    "CHE",
    "CHW",
    "CRF",
    "SWA",
    "BST",
    "DER",
    "KHL",
    "LCE",
    "LND",
    "NGM",
    "PTE",
    "PLY",
    "POR",
    "STH",
    "STE",
    "SND",
    "WSM",
    "WLV",
    "YOR",
    "CLK",
    "CLR",
    "CWY",
    "CKT",
    "CON",
    "DUR",
    "COV",
    "CGV",
    "CMA",
    "DAL",
    "DEN",
    "DBY",
    "DRS",
    "DRY",
    "DEV",
    "DNC",
    "DOR",
    "DOW",
    "DUD",
    "DGY",
    "DND",
    "DGN",
    "EAY",
    "EDU",
    "ELN",
    "ERW",
    "ERY",
    "ESX",
    "EDH",
    "ENG",
    "ESS",
    "FAL",
    "FMO",
    "FER",
    "FIF",
    "FLN",
    "GAT",
    "GLG",
    "GLS",
    "GWN",
    "HAL",
    "HAM",
    "HPL",
    "HEF",
    "HRT",
    "HLD",
    "IVC",
    "IOW",
    "IOS",
    "KEN",
    "KIR",
    "KWL",
    "LAN",
    "LRN",
    "LDS",
    "LEC",
    "LMV",
    "LIN",
    "LBC",
    "LSB",
    "LIV",
    "BDG",
    "BNE",
    "BEX",
    "BEN",
    "BRY",
    "CMD",
    "CRY",
    "EAL",
    "ENF",
    "HCK",
    "HMF",
    "HRY",
    "HRW",
    "HAV",
    "HIL",
    "HNS",
    "ISL",
    "LBH",
    "LEW",
    "MRT",
    "NWM",
    "RDB",
    "RIC",
    "SWK",
    "STN",
    "TWH",
    "WFT",
    "WND",
    "MFT",
    "MAN",
    "MDW",
    "MTY",
    "WGN",
    "MEA",
    "MUL",
    "MDB",
    "MLN",
    "MIK",
    "MON",
    "MRY",
    "MYL",
    "NTL",
    "NET",
    "NWP",
    "NYM",
    "NMD",
    "NTA",
    "NFK",
    "NAY",
    "NDN",
    "NEL",
    "NLK",
    "NLN",
    "NSM",
    "NTY",
    "NYK",
    "NTH",
    "NIR",
    "NBL",
    "NTT",
    "OLD",
    "OMH",
    "ORK",
    "ELS",
    "OXF",
    "PEM",
    "PKN",
    "POL",
    "POW",
    "RDG",
    "RCC",
    "RFW",
    "RCT",
    "RCH",
    "ROT",
    "GRE",
    "KEC",
    "KTT",
    "RUT",
    "SH-HL",
    "SLF",
    "SAW",
    "SCT",
    "SCB",
    "SFT",
    "SHF",
    "ZET",
    "SHR",
    "SLG",
    "SOL",
    "SOM",
    "SAY",
    "SGC",
    "SLK",
    "STY",
    "SOS",
    "SHN",
    "STS",
    "STG",
    "SKP",
    "STT",
    "STB",
    "SFK",
    "SRY",
    "SWD",
    "TAM",
    "TFW",
    "THR",
    "TOB",
    "TOF",
    "TRF",
    "UKM",
    "VGL",
    "WKF",
    "WLS",
    "WLL",
    "WRT",
    "WAR",
    "WBK",
    "WDU",
    "WLN",
    "WSX",
    "WIL",
    "WNM",
    "WRL",
    "WOK",
    "WOR",
    "WRX",
  ],
  Germany: [
    "BW",
    "BY",
    "BE",
    "BB",
    "HB",
    "HH",
    "HE",
    "NI",
    "MV",
    "NW",
    "RP",
    "SL",
    "SN",
    "ST",
    "SH",
    "TH",
  ],
  Ukraine: [
    "ARC",
    "CK",
    "CH",
    "CV",
    "DP",
    "DT",
    "IF",
    "KK",
    "KS",
    "KM",
    "KH",
    "KV",
    "LH",
    "LV",
    "MY",
    "OD",
    "PL",
    "RV",
    "SM",
    "TP",
    "VI",
    "VO",
    "ZK",
    "ZP",
    "ZT",
  ],
  USA: [
    "AL",
    "AK",
    "AS",
    "AZ",
    "AR",
    "UM-81",
    "CA",
    "CO",
    "CT",
    "DE",
    "DC",
    "FL",
    "GA",
    "GU",
    "HI",
    "UM-84",
    "ID",
    "IL",
    "IN",
    "IA",
    "UM-86",
    "UM-67",
    "KS",
    "KY",
    "UM-89",
    "LA",
    "ME",
    "MD",
    "MA",
    "MI",
    "UM-71",
    "MN",
    "MS",
    "MO",
    "MT",
    "UM-76",
    "NE",
    "NV",
    "NH",
    "NJ",
    "NM",
    "NY",
    "NC",
    "ND",
    "MP",
    "OH",
    "OK",
    "OR",
    "UM-95",
    "PA",
    "PR",
    "RI",
    "SC",
    "SD",
    "TN",
    "TX",
    "UM",
    "VI",
    "UT",
    "VT",
    "VA",
    "UM-79",
    "WA",
    "WV",
    "WI",
    "WY",
  ],
};
