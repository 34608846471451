import React, { useEffect, useState } from "react";
import { Card, Typography, Tooltip } from "antd";
import Carousel from "./Carousel";
import List from "./list";
import http from "../../../../../services/http";
import { GridViewOutlined, List as ListIcon } from "@mui/icons-material";

const { Title } = Typography;

const InvestorActivity = ({ data = {} }) => {
  const [activities, setActivities] = useState([]);
  const [activitiesCompany, setActivitiesCompany] = useState([]);
  const [gridView, setGridView] = useState(true);

  useEffect(() => {
    (async () => {
      if (data.id) {
        let response = await http.get(`activities/${data.id}`);
        setActivities(response.data);
        setActivitiesCompany(data);
      }
    })();
  }, [data]);

  return (
    <React.Fragment>
      <div className="spacer" />
      <Title level={5}>Activity</Title>
      <Card bordered={false} className="investor-investments-activity">
        {activities.length > 0 && (
          <div className="investor-activity-filters">
            <Tooltip title="Grid view">
              <button
                className={gridView ? "selected" : ""}
                onClick={() => setGridView(true)}
              >
                <GridViewOutlined />
              </button>
            </Tooltip>
            <Tooltip title="List view">
              <button
                className={!gridView ? "selected" : ""}
                onClick={() => setGridView(false)}
              >
                <ListIcon />
              </button>
            </Tooltip>
          </div>
        )}
        <div style={{ display: gridView ? "block" : "none" }}>
          <Carousel
            activities={activities}
            activitiesCompany={activitiesCompany}
          />
        </div>
        <div style={{ display: !gridView ? "block" : "none" }}>
          <List activities={activities} activitiesCompany={activitiesCompany} />
        </div>
      </Card>
    </React.Fragment>
  );
};

export default InvestorActivity;
