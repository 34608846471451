import React, { useState, useEffect } from "react";
import { Card } from "antd";
import { DataTable, SearchBox } from "./components";
import http from "../../../../services/http";
import { useLocation } from "react-router-dom";
import "./styles.scss";

function Dataset() {
  // STATES
  const [initLoad, setInitLoad] = useState(true);
  const [isLoading, setLoading] = useState(false);
  const [selectedFilters, setselectedFilters] = useState({});
  const [selectedSorter, setselectedSorter] = useState(null);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [investors, setInvestors] = useState([]);

  // CONST VALS
  const DATA_LIMIT = 10;
  const { search } = useLocation();

  // HELPERS
  const sortHandler = (sorter) => {
    if (!sorter.order) {
      setselectedSorter(null);
      return;
    }
    if (!sorter.columnKey) return;
    let sortingoption = sorter.order === "descend" ? "-" : "";
    let selectedSorter = sortingoption + sorter.columnKey;
    setselectedSorter(selectedSorter);
  };
  const getInvestorsHelper = async (fromFilter) => {
    setLoading(true);
    const appliedFilter = { ...selectedFilters };
    Object.keys(appliedFilter).forEach((key) => {
      if (appliedFilter[key] === undefined || appliedFilter[key]?.length === 0)
        delete appliedFilter[key];
    });
    const url = new URL(window.location.origin + window.location.pathname);
    if (Object.keys(appliedFilter).length >= 1) {
      Object.keys(appliedFilter).forEach((key) => {
        url.searchParams.set(key, appliedFilter[key]);
      });
    }
    window.history.pushState(null, "", url.toString());
    const currentPage =
      fromFilter && Object.keys(appliedFilter).length >= 1 ? 1 : page;
    const params = {
      ...appliedFilter,
      offset: (currentPage - 1) * DATA_LIMIT,
      limit: DATA_LIMIT,
    };
    if (selectedSorter) params.sort = selectedSorter;
    const queryparams = new URLSearchParams(params).toString();
    const { data } = await http.get(`dataset?${queryparams}`);
    setLoading(false);
    // setInitLoad(false);
    setPage(currentPage);
    setInvestors(data?.data);
    setTotal(data?.total);
  };

  useEffect(() => {
    if (initLoad) return;
    getInvestorsHelper();
  }, [page, selectedSorter]);

  useEffect(() => {
    if (initLoad) return;
    getInvestorsHelper(true);
  }, [selectedFilters]);

  useEffect(() => {
    const params = new URLSearchParams(search);
    const filters = {};
    for (let [key, value] of params) {
      filters[key] = value;
    }
    setInitLoad(false);
    setselectedFilters(filters);
  }, [search]);

  return (
    <>
      <h2 className="dashboard-header">Dataset</h2>
      <Card className="dataset-wrapper">
        <SearchBox
          selectedFilter={selectedFilters}
          setFilters={setselectedFilters}
        />
        <DataTable
          isLoading={isLoading}
          data={investors}
          total={total}
          page={page}
          setPage={setPage}
          sortHandler={sortHandler}
        />
      </Card>
    </>
  );
}

export default Dataset;
