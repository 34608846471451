import { SET_LOADER, SET_SUBMISSION_ERROR } from "../types/app";

export const setLoader = (params) => ({
  type: SET_LOADER,
  payload: params,
});

export const setSubmissionError = (params) => ({
  type: SET_SUBMISSION_ERROR,
  payload: params,
});
