import React, { useEffect, useState } from "react";

import { Card, Tabs } from "antd";
import Form from "../components/form";

import DataSet from "../../../../../dataset.json";
import DataSetOutput from "../../../../../dataset_output.json";

import Button from "@mui/material/Button";
import {
  INVESTOR_NARROW_SPECIALTY_FILTERS,
  temo_narrow_specialty,
} from "../../../../../constant";

const Questionaire = ({
  title,
  questions,
  currentStep,
  sets = {},
  dataSet,
  state = [],
  setState,
}) => {
  const isGoals = currentStep === 0;
  // const [narrowOptions, setnarrowOptions] = useState([]);
  const narrowOptions = temo_narrow_specialty();

  const handleChange = (index, label, option) => {
    // if (label === "broad_specialty_input") {
    //   const options = [];
    //   if (INVESTOR_NARROW_SPECIALTY_FILTERS[option.value]) {
    //     INVESTOR_NARROW_SPECIALTY_FILTERS[option.value].forEach((item) => {
    //       options.push({
    //         label: item,
    //         value: item,
    //       });
    //     });
    //   }
    //   setnarrowOptions(options);
    // }
    let data = state;
    data[index].value = option.value;
    if (isGoals && data.length >= index + 1) {
      for (let i = index + 1; i < data.length; i++) {
        if (data[i].type === "range") {
          data[i].value = 1;
        } else {
          data[i].value = typeof data[i].value === "object" ? [] : "";
        }
      }
    }
    let newQuestions = [];
    if (option.label && typeof option.label === "object") {
      newQuestions = questions.filter((g) => option.label.includes(g.label));
    } else {
      newQuestions = questions.filter((g) => g.label === option.label);
    }
    let filteredQuestions = data.filter(
      (q) => q.parent.includes(label) === false
    );

    data = [...filteredQuestions, ...newQuestions];

    setState({ ...sets, [title]: data });
  };

  const changeValue = (index, val, label) => {
    let data = state;
    data[index].value = val;

    let item = questions.filter((g) => g.label === label)[0];
    if (isGoals && data.length >= index + 1) {
      for (let i = index + 1; i < data.length; i++) {
        if (data[i].type === "range") {
          data[i].value = 1;
        } else {
          data[i].value = typeof data[i].value === "object" ? [] : "";
        }
      }
    }
    // if (data.length >= index + 2) {
    //   data[index + 1].value = "";
    // }
    // if (data.length >= index + 3) {
    //   data[index + 2].value = "";
    // }
    // if (data.length >= index + 4) {
    //   data[index + 3].value = "";
    // }
    if (item) {
      if ("target" in item) {
        let filteredQuestions = data.filter(
          (q) => q.parent.includes(item["label"]) === false
        );
        if (
          item["target"][0].value === "all" ||
          val.some((x) => x.toLowerCase() === item["target"][0].value)
        ) {
          let newQuestions = questions.filter(
            (g) => g.label === item["target"][0].label
          )[0];
          if (data.some((x) => x.label === newQuestions.label)) {
            data = [...data];
          } else {
            data = [...data, newQuestions];
          }
          // data = [...filteredQuestions, ...newQuestions];
        }
        // else {
        //   if (data.length >= index + 2) {
        //     data[index + 1].value = "";
        //   }
        //   if (data.length >= index + 3) {
        //     data[index + 2].value = "";
        //   }
        //   if (data.length >= index + 4) {
        //     data[index + 3].value = "";
        //   }
        //   data = [...filteredQuestions];
        // }
      }
    }

    setState({ ...sets, [title]: data });
  };

  // useEffect(() => {
  //   const broadSpecialty = state.find(
  //     (q) => q.key === "broad_specialty_input"
  //   )?.value;
  //   if (!broadSpecialty) return;
  //   if (INVESTOR_NARROW_SPECIALTY_FILTERS[broadSpecialty]) {
  //     setnarrowOptions(
  //       INVESTOR_NARROW_SPECIALTY_FILTERS[broadSpecialty].map((item) => {
  //         return {
  //           label: item,
  //           value: item,
  //         };
  //       })
  //     );
  //   }
  // }, [dataSet]);

  return (
    <Card bordered={false} sx={{ width: "100%" }}>
      {state.map((question, index) => {
        if (!question.options && !question.type) return null;
        return (
          <Form
            key={`form-${index}`}
            index={index}
            data={question}
            form={state}
            setForm={setState}
            onChange={handleChange}
            onChangeInput={changeValue}
            narrowOptions={narrowOptions}
          />
        );
      })}
    </Card>
  );
};

export default Questionaire;
