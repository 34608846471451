import * as React from "react"

const SvgComponent = (props) => (
  <svg
    width={1188}
    height={1157}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g opacity={0.25}>
      <path
        d="M956.682 931.326s-202.978-28.767-292.697-106.727c-103.954-90.327-30.88-279.318-158.566-335.936-109.87-48.718-233.018 84.614-346.867 42.86-119.73-43.911-78.508-209.317-194.678-259.495-72.014-31.106-211.237-23.837-211.237-23.837"
        stroke="#D0BBFE"
      />
      <path
        d="M1088.9 977.383S885.49 948.29 795.483 870.115c-104.286-90.578-31.35-279.672-159.366-336.538-110.152-48.93-233.341 84.371-347.471 42.407-120.025-44.133-78.967-209.663-195.434-260.064-72.198-31.244-211.678-24.169-211.678-24.169"
        stroke="#EAB8FF"
      />
      <path
        d="M871.792 896.366s-154.679-44.645-231.327-108.338c-88.807-73.797-58.41-192.035-160.6-246.074-87.931-46.499-162.648 28.007-252.657-14.296-94.658-44.487-84.969-150.799-177.773-199.066-57.529-29.921-160.199-42.352-160.199-42.352"
        stroke="#CBB8FE"
      />
      <path
        d="M880.592 900.83S726.208 855.8 649.535 792.14c-88.836-73.759-59.02-191.238-161.117-245.4-87.85-46.604-162.029 27.199-251.933-15.24-94.549-44.63-85.327-150.332-178.043-198.714-57.474-29.992-159.877-42.772-159.877-42.772"
        stroke="#C6B6FD"
      />
      <path
        d="M889.655 904.938S734.976 860.292 658.329 796.6c-88.808-73.797-58.411-192.035-160.601-246.075-87.931-46.499-162.648 28.007-252.656-14.295-94.659-44.488-84.969-150.8-177.774-199.067C9.77 307.243-92.9 294.811-92.9 294.811"
        stroke="#C1B3FD"
      />
      <path
        d="M898.979 908.711S744.3 864.066 667.653 800.373c-88.808-73.797-58.41-192.035-160.601-246.074-87.93-46.499-162.647 28.006-252.656-14.296-94.658-44.487-84.969-150.8-177.773-199.066-57.53-29.921-160.2-42.352-160.2-42.352"
        stroke="#BCB1FD"
      />
      <path
        d="M909.409 913.319s-154.823-44.753-231.566-108.518c-88.919-73.88-58.568-192.153-160.868-246.275-88.025-46.569-162.755 27.926-252.857-14.446-94.757-44.562-85.123-150.915-178.026-199.257-57.59-29.966-160.346-42.462-160.346-42.462"
        stroke="#B7AEFD"
      />
      <path
        d="M918.729 917.094S764.05 872.448 687.403 808.756c-88.808-73.797-58.41-192.035-160.601-246.075-87.93-46.498-162.647 28.007-252.656-14.295-94.658-44.487-84.969-150.8-177.773-199.067-57.53-29.92-160.2-42.351-160.2-42.351"
        stroke="#B2ACFC"
      />
      <path
        d="M928.054 920.867s-154.679-44.645-231.327-108.338c-88.807-73.797-58.41-192.035-160.6-246.074-87.931-46.499-162.648 28.007-252.657-14.296-94.658-44.487-84.969-150.8-177.773-199.066-57.53-29.921-160.199-42.352-160.199-42.352"
        stroke="#ADA9FC"
      />
      <path
        d="M936.585 925.668S781.906 881.023 705.258 817.33c-88.807-73.797-58.41-192.035-160.6-246.074-87.931-46.499-162.648 28.006-252.657-14.296-94.658-44.487-84.969-150.8-177.773-199.067-57.529-29.92-160.199-42.351-160.199-42.351"
        stroke="#A8A7FC"
      />
      <path
        d="M945.913 929.443s-154.679-44.645-231.327-108.337c-88.807-73.798-58.41-192.036-160.6-246.075-87.931-46.499-162.648 28.007-252.657-14.295-94.658-44.488-84.969-150.8-177.773-199.067-57.529-29.921-160.198-42.352-160.198-42.352"
        stroke="#D0BBFE"
      />
      <path
        d="M956.339 934.051s-154.824-44.754-231.567-108.518c-88.918-73.881-58.567-192.153-160.867-246.275-88.025-46.57-162.756 27.925-252.858-14.447-94.756-44.561-85.122-150.915-178.025-199.256-57.59-29.967-160.346-42.462-160.346-42.462"
        stroke="#D0BBFE"
      />
    </g>
  </svg>
)

export default SvgComponent
