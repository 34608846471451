import React, { useState, useEffect } from "react";
import { Button, Input, Drawer, Form, Select } from "antd";
import {
  BUYER_STATES_FILTERS,
  BUYER_TYPES_FILTERS,
} from "../../../../../constant";

const FilterDrawer = ({ isOpen, toggle, setFilter, isRemoved }) => {
  const [isPageLoaded, setPageLoaded] = useState(false);
  const [form] = Form.useForm();

  const handleResetForm = (isToggle) => {
    setFilter(null);
    form.resetFields();
    if (!isToggle) toggle();
  };
  const handleFormFinish = (values) => {
    const isState = values?.state;
    const isType = values?.type?.length;

    if (!isState && !isType) return;
    const payload = {
      ...values,
    };
    if (isType) payload["type"] = values.type.toString();
    if (isState) payload["state"] = values.state.toString();
    setFilter(payload);
    // const tempdata = data?.filter((dt) => {
    //   if (isType && !isState) {
    //     return values?.type.some(
    //       (dta) => dta.toLowerCase() === dt?.type.toLowerCase()
    //     );
    //   }
    //   if (!isType && isState) {
    //     return dt.state?.toLowerCase().includes(values.state.toLowerCase());
    //   }
    //   return (
    //     values?.type.some(
    //       (dta) => dta.toLowerCase() === dt?.type.toLowerCase()
    //     ) && dt.state?.toLowerCase().includes(values.state.toLowerCase())
    //   );
    // });
    toggle();
  };

  useEffect(() => {
    isPageLoaded && handleResetForm(true);
    setPageLoaded(true);
  }, [isRemoved]);

  return (
    <Drawer title="Filter" placement="right" onClose={toggle} visible={isOpen}>
      <Form form={form} className="form" onFinish={handleFormFinish}>
        <label className="form-lbl">Location:</label>
        <Form.Item name={"state"}>
          <Select
            name="type"
            placeholder="Filter by state"
            className="form-input"
            mode="multiple">
            {BUYER_STATES_FILTERS.map((item, index) => (
              <Select.Option key={index} value={item.value}>
                {item.label}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <label className="form-lbl">Type:</label>
        <Form.Item name={"type"}>
          <Select
            name="type"
            placeholder="Filter by type"
            className="form-input"
            mode="multiple">
            {BUYER_TYPES_FILTERS.map((item, index) => (
              <Select.Option key={index} value={item.value}>
                {item.label}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item>
          <Button
            htmlType="submit"
            className="form-btn"
            variant="contained"
            size="large"
            style={{
              minWidth: "100%",
              marginTop: 0,
              color: "#fff",
              background: "#605BFF",
            }}>
            Apply Filters
          </Button>
          <span
            className="ant-btn"
            style={{ minWidth: "100%", marginTop: 15 }}
            onClick={handleResetForm}>
            Reset Filters
          </span>
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default FilterDrawer;
