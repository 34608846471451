import * as React from "react"

const SvgComponent = (props) => (
  <svg
    width={32}
    height={32}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M28 20a2.667 2.667 0 0 1-2.667 2.667h-16L4 28V6.667A2.667 2.667 0 0 1 6.667 4h18.666A2.667 2.667 0 0 1 28 6.667V20Z"
      stroke="#2D5CDC"
      strokeWidth={0.8}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default SvgComponent
