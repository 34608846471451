import axios from "axios";
import { api_base_url } from "../config/api";
import Cookie from "js-cookie";

// axios.defaults.withCredentials = true;

class HttpService {
  config(config, endpoint) {
    config.timeout = 30000;
    const role = Cookie.get("role");
    let token = null;

    if (role === "leads") {
      token = Cookie.get("token_leads");
    } else if (role === "user") {
      token = Cookie.get("token_user");
    }

    if (!endpoint.includes("http://") && !endpoint.includes("https://"))
      config.baseURL = api_base_url;

    if (!config.headers) config.headers = {};

    if (
      token &&
      !config.headers.Authorization &&
      !endpoint.includes("http://") &&
      !endpoint.includes("https://")
    )
      config.headers.Authorization = `Bearer ${token}`;

    return config;
  }

  /** Created this method handler for error to process necessary steps before returning reject response of the promise. */
  errorhandler(errorData) {
    /**
     * The following cases are the reason why this handler is created
     * - Refreshing expired tokens.
     */
    return errorData;
  }

  get(endpoint, params, config = { headers: {} }) {
    return new Promise(async (resolve, reject) => {
      config.params = params;
      config = await this.config(config, endpoint);
      axios
        .get(endpoint, config)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => reject(this.errorhandler(err)));
    });
  }

  post(endpoint, params, config = { headers: {} }) {
    return new Promise(async (resolve, reject) => {
      config = await this.config(config, endpoint);
      axios
        .post(endpoint, params, config)
        .then((response) => resolve(response))
        .catch((err) => reject(this.errorhandler(err)));
    });
  }

  put(endpoint, params, config = { headers: {} }) {
    return new Promise(async (resolve, reject) => {
      config = await this.config(config, endpoint);
      axios
        .put(endpoint, params, config)
        .then((response) => resolve(response))
        .catch((err) => reject(this.errorhandler(err)));
    });
  }

  patch(endpoint, params, config = { headers: {} }) {
    return new Promise(async (resolve, reject) => {
      config = await this.config(config, endpoint);
      axios
        .patch(endpoint, params, config)
        .then((response) => resolve(response))
        .catch((err) => reject(this.errorhandler(err)));
    });
  }

  delete(endpoint, params, config = { headers: {} }) {
    return new Promise(async (resolve, reject) => {
      config.params = params;
      config = await this.config(config, endpoint);
      axios
        .delete(endpoint, config)
        .then((response) => resolve(response))
        .catch((err) => reject(this.errorhandler(err)));
    });
  }
}

export default new HttpService();
