import React, { useState, useEffect } from "react";
import { Card } from "antd";
import { replaceValueInDataSet } from "../../../../utils";
import { fetchPrivateImage } from "../../../../helpers/fetchImage";
import NoDataIcon from "../../../../assets/svgs/no-data.svg";
import { BounceLoader } from "react-spinners";
import SourceModal from "../../../../components/source-modal/index";

const InvestorLogoBox = ({ data }) => {
  const [isLoading, setLoading] = useState(true);
  const [logo, setLogo] = useState(null);
  useEffect(() => {
    setLoading(true);
    fetchPrivateImage(data.target_logo)
      .then((res) => {
        setLogo(res);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, [data]);
  return (
    <div className="thumb">
      {isLoading ? (
        <BounceLoader size={10} color="rgb(234, 187, 254)" />
      ) : (
        <img src={logo} alt={data.name} />
      )}
    </div>
  );
};

function InvestorActivities({ activities }) {
  const [selectedData, setselectedData] = useState(null);
  const [sourcePreview, setsourcePreview] = useState(false);

  const handleSourcePreview = (data) => {
    let sources = [];
    if (data.source) {
      sources = data.source.split(",");
    }
    setselectedData(sources);
    setsourcePreview(true);
  };
  return (
    <Card className="activities-detail">
      <h3 className="title">Activity</h3>
      <div className="list-container">
        <div className="list-head">
          <div className="list-item">Name</div>
          <div className="list-item">Target</div>
          <div className="list-item">Transaction type</div>
          <div className="list-item">Amount</div>
          <div className="list-item">Transaction date</div>
          <div className="list-item">Status</div>
          <div className="list-item">Source</div>
        </div>
        <div className="list-body">
          {activities.length >= 1 ? (
            activities.map((activity, index) => (
              <div className="list-row" key={index}>
                <div className="list-item">
                  <InvestorLogoBox data={activity} />
                  <span className="name">
                    {activity.name ? replaceValueInDataSet(activity.name) : "-"}
                  </span>
                </div>
                <div className="list-item">
                  {activity.target_name
                    ? replaceValueInDataSet(activity.target_name)
                    : "-"}
                </div>
                <div className="list-item">
                  {activity.transaction_type
                    ? replaceValueInDataSet(activity.transaction_type)
                    : "-"}
                </div>
                <div className="list-item">
                  {activity.amount ? `${activity.amount}m` : "-"}
                </div>
                <div className="list-item">
                  {activity.date_of_transaction
                    ? replaceValueInDataSet(activity.date_of_transaction)
                    : "-"}
                </div>
                <div className="list-item">
                  {activity.status
                    ? replaceValueInDataSet(activity.status)
                    : "-"}
                </div>
                <div className="list-item">
                  <button onClick={() => handleSourcePreview(activity)}>
                    View
                  </button>
                </div>
              </div>
            ))
          ) : (
            <div className="no-activities-data">
              <img src={NoDataIcon} alt="no-data" />
              No Activity Found
            </div>
          )}
        </div>
      </div>
      <SourceModal
        show={sourcePreview}
        sources={selectedData}
        onClose={() => setsourcePreview(false)}
      />
    </Card>
  );
}

export default InvestorActivities;
