import React from "react";
import { Card, Typography } from "antd";
import AttribuesThumnail from "../../../../../assets/images/attributes";

const { Meta } = Card;
const { Title } = Typography;

const CompanyAttributes = ({ attributes }) => {
  const data = [
    {
      title: "NAICS Industry",
      icon: AttribuesThumnail.Naics,
      value: attributes["NAICS-Master-Dropdown"] || `-`,
    },
    {
      title: "NAICS Sub-Industry",
      icon: AttribuesThumnail.NaicsSub,
      value: attributes["NAICS-Sub-Dropdown"] || `-`,
    },
    {
      title: "Narrow Specialty",
      icon: AttribuesThumnail.Speciality,
      value: attributes["narrow_specialty_input"] || `-`,
    },
    {
      title: "Company Strengths",
      icon: AttribuesThumnail.Descriptor,
      value: attributes["descriptor_input"]
        ? attributes["descriptor_input"].join(", ")
        : `-`,
    },
    {
      title: "Supply Chain Position",
      icon: AttribuesThumnail.SupplyChain,
      value: attributes["supply_chain_dropdown"] || `-`,
    },
    {
      title: "Area(s) of Operation",
      icon: AttribuesThumnail.Area,
      value: attributes["area_of_operations_dropdown"]
        ? attributes["area_of_operations_dropdown"].join(", ")
        : `-`,
    },
    {
      title: "Products",
      icon: AttribuesThumnail.Product,
      value: attributes["product_input"]
        ? attributes["product_input"].join(", ")
        : `-`,
    },
    {
      title: "Services",
      icon: AttribuesThumnail.Services,
      value: attributes["service_input"]
        ? attributes["service_input"].join(", ")
        : `-`,
    },
    {
      title: "Workplace Culture Type",
      icon: AttribuesThumnail.Workplace,
      value: attributes["workplace_culture_dropdown"]
        ? attributes["workplace_culture_dropdown"].join(", ")
        : `-`,
    },
  ];
  return (
    <Card bordered={false} className="home-card">
      <Title level={3}>Attributes</Title>
      {data.map((item, index) => (
        <Meta
          key={index}
          style={{
            marginBottom: 8,
            marginTop: index == 0 ? 2 : 0,
            borderBottom: "1px solid #f0f0f0",
            paddingBottom: 8,
          }}
          avatar={
            <img src={item.icon} alt="" style={{ width: 45, marginTop: 3 }} />
          }
          title={
            <>
              <strong>{item.title}</strong> <br />
              <span style={{ fontSize: 14, color: "#A3AED0" }}>
                {item.value}
              </span>
              <br />
            </>
          }
        />
      ))}
    </Card>
  );
};

export default CompanyAttributes;
